import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
} from 'utils/forms';
import { isRequired } from 'utils/forms/validators';

function isValidLetterOrPositiveOrNull(value, label, message) {
  const isValid =
    !value || !!value.match(/^[ABCDNI]+$/) || (!!value.match(/^[0-9]+$/) && value >= 0);
  return {
    valid: isValid,
    message: !isValid ? message || `${label || 'Le champ'} doit être supérieur ou égal à 0` : '',
  };
}

export default function deceasedFormBuilder({ initialValues = null, mode = FORM_MODE_CREATE }) {
  const fields = {
    deceased: new Field({
      initValue: (initialValues && initialValues.deceased) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired, isValidLetterOrPositiveOrNull],
      validateOn: [FORM_VALID_ON_BLUR],
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
