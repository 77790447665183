import { Fab, Grid, Paper, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import axios from 'axios';
import AccountInfo from 'components/Admin/AccountInfo';
import accountInfoFormBuilder from 'components/Admin/accountInfoFormDataBuilder';
import { action, autorun, decorate, observable, transaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import React from 'react';
import compose from 'utils/compose';
import { FORM_MODE_CONSULT, FORM_MODE_CREATE, isConsultationMode } from 'utils/forms';

class AccountCreatePage extends React.Component {
  promise;
  accountInfoForm;
  formData;
  mode = FORM_MODE_CREATE;

  constructor(props) {
    super(props);

    autorun(() => {
      this.accountInfoForm = accountInfoFormBuilder({
        initialValues: this.formData,
        mode: this.mode,
      });
    });
  }

  doPostForm = values => this.assignPromise(axios.post(`/admin/accounts`, values));

  assignPromise = action(fetch => observable(fromPromise(fetch)));

  handleFormSubmit = values => {
    const { notificationStore, routingStore } = this.props;
    return this.doPostForm(values).then(({ data }) =>
      transaction(() => {
        this.formData = data;
        this.mode = FORM_MODE_CONSULT;
        notificationStore.notifySuccess('Compte créé avec succès');
        routingStore.push(`/accounts/${data.id}`);
      })
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="center" alignItems="center" alignContent="center">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <AccountInfo
              form={this.accountInfoForm}
              onSubmit={this.handleFormSubmit}
              mode={this.mode}
            />
          </Paper>
        </Grid>
        {isConsultationMode(this.mode) && (
          <Grid item xs={12}>
            <Grid container alignContent="center" justify="flex-end">
              <Grid item>
                <Fab
                  color="secondary"
                  aria-label="Edit"
                  className={classes.fab}
                  onClick={this.handleClickEdit}>
                  <Edit />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

const styles = theme => ({
  fab: {
    margin: theme.spacing(1),
  },
  paper: {
    minHeight: 510,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
});
export default compose(
  inject('notificationStore', 'routingStore'),
  withStyles(styles),
  observer
)(
  decorate(AccountCreatePage, {
    accountInfoForm: observable,
    formData: observable,
    mode: observable,
    promise: observable,
  })
);
