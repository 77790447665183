import { Collapse, Grid, MenuItem, TextField } from '@material-ui/core';
import Selects from 'components/form/common/Selects';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { getInputFieldProps } from 'utils/forms';
import withEditableForm from '../common/withEditableForm';

const EventLocation = observer(({ form }) => {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        {/* <Grid item xs={12}>
          <Selects
            required
            fullWidth
            label="Département"
            placeholder="Département"
            input={<Input name="dept" />}
            {...getInputFieldProps(form.department)}>
            {departments.map((v, i) => (
              <MenuItem key={`${i}-${v}`} value={v}>
                {t(`departments.${v}`, v)}
              </MenuItem>
            ))}
          </Selects>
        </Grid> */}

        <Grid item xs={12}>
          <TextField
            required
            multiline
            rows={3}
            rowsMax={5}
            id="address"
            name="address"
            label="Adresse"
            fullWidth
            autoComplete="address-line1"
            {...getInputFieldProps(form.address)}
          />
        </Grid>
        <Grid item xs={12}>
          <Selects
            required
            fullWidth
            label="Description du lieu"
            placeholder="Description du lieu"
            {...getInputFieldProps(form.description)}>
            <MenuItem value="">
              <em>Aucun choix</em>
            </MenuItem>
            <MenuItem value={'BAT'}>Bâtiment</MenuItem>
            <MenuItem value={'VOR'}>Voie routière</MenuItem>
            <MenuItem value={'ETS'}>Institution sanitaire</MenuItem>
            <MenuItem value={'OTH'}>Autre</MenuItem>
          </Selects>
        </Grid>

        <Collapse in={form.description.value === 'OTH'}>
          <Grid item xs={12}>
            <TextField
              required
              id="description-other"
              name="description-other"
              fullWidth
              placeholder="Description du lieu"
              {...getInputFieldProps(form.descriptionOther)}
            />
          </Grid>
        </Collapse>

        <Grid item xs={12}>
          <Selects
            required
            fullWidth
            label="Accessibilité"
            placeholder="Accessibilité du lieu"
            {...getInputFieldProps(form.accessibility)}>
            <MenuItem value="">
              <em>Aucun choix</em>
            </MenuItem>
            <MenuItem value={'ESY'}>Bonne</MenuItem>
            <MenuItem value={'AVG'}>Moyenne</MenuItem>
            <MenuItem value={'HRD'}>Faible</MenuItem>
            <MenuItem value={'WLK'}>À pieds seulement (route coupée)</MenuItem>
            {/* <MenuItem value={'HLC'}>En hélicoptère seulement</MenuItem> */}
          </Selects>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default withEditableForm(EventLocation);
