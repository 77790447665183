import NotificationManager from 'components/common/NotificationManager';
import { createBrowserHistory } from 'history';
import MainLayout from 'MainLayout';
import { Provider } from 'mobx-react';
// import { syncHistoryWithStore } from 'mobx-react-router';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Router } from 'react-router';
import RootStore from 'state/RootStore';
import './App.css';
import axios from 'axios';
import SessionRefresher from 'components/Login/SessionRefresher';
import { syncHistoryWithStore } from 'state/syncHistoryWithStore';
import ErrorBoundary from 'components/ErrorBoundary';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const App = () => {
  const rootStore = new RootStore();
  const browserHistory = createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, rootStore.routingStore);
  return (
    <div className="App">
      <Provider {...rootStore}>
        <Router history={history}>
          <ErrorBoundary>
            <SessionRefresher />
            <NotificationManager />
            <MainLayout />
          </ErrorBoundary>
        </Router>
      </Provider>
    </div>
  );
};

export default hot(App);
