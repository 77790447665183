import React from 'react';
import { Grid, FormControlLabel, Radio, TextField, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { getInputFieldProps } from 'utils/forms';
import withEditableForm from '../common/withEditableForm';
import compose from 'utils/compose';
import RadioGroups from 'components/form/common/RadioGroups';

const styles = theme => ({
  input: {
    minHeight: '12px',
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
  },
});

const Injuried = ({ classes, form }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RadioGroups
            required
            aria-label="Injuried estimation"
            name="injuried"
            label="Choisir une estimation"
            {...getInputFieldProps(form.injuried)}>
            <FormControlLabel value={'I'} control={<Radio color="primary" />} label="Inconnu" />
            <FormControlLabel value={'0'} control={<Radio color="primary" />} label="Aucun" />
            <FormControlLabel value={'A'} control={<Radio color="primary" />} label="Moins de 10" />
            <FormControlLabel value={'B'} control={<Radio color="primary" />} label="10 à 50" />
            <FormControlLabel value={'C'} control={<Radio color="primary" />} label="50 à 100" />
            <FormControlLabel value={'D'} control={<Radio color="primary" />} label="Plus de 100" />
          </RadioGroups>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            aria-label="Injuried exact count"
            name="injuried-exact"
            label="Nombre exact (si connu)"
            inputProps={{
              min: 0,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              className: classes.input,
            }}
            {...getInputFieldProps(
              form.injuried,
              {},
              { formatter: val => (isNaN(val) ? '' : val) }
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default compose(
  withEditableForm,
  withStyles(styles),
  observer
)(Injuried);
