import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
} from 'utils/forms';
import { isRequired } from 'utils/forms/validators';

export default function eventTypeFormBuilder({ initialValues = null, mode = FORM_MODE_CREATE }) {
  const fields = {
    type: new Field({
      initValue: (initialValues && initialValues.type) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'EventType',
    }),
    other: new Field({
      initValue: (initialValues && initialValues.other) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [
        (value, label) => {
          return fields.type.value === 'OTH'
            ? isRequired(value, label)
            : () => ({
                valid: true,
              });
        },
      ],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'EventTypeOther',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
