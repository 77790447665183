import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import compose from 'utils/compose';
import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';
import { withStyles } from '@material-ui/core';

const ResponsiveDialog = ({ classes, fullScreen, notificationStore }) => {
  const [open, setOpen] = React.useState(false);

  const {
    title,
    message = '',
    modal = true,
    onCancel,
    onConfirm,
    onConfirmLabel = 'Confirmer',
    onCancelLabel = 'Annuler',
  } = notificationStore.dialog || {};
  const handleConfirm = () => {
    setOpen(false);
    if (onConfirm) onConfirm();
  };

  const handleCancel = () => {
    setOpen(false);
    if (onCancel) onCancel();
  };

  useEffect(() => {
    if (notificationStore.dialog) setOpen(true);
  }, [notificationStore.dialog]);

  return notificationStore.dialog ? (
    <Dialog
      disableBackdropClick={modal}
      disableEscapeKeyDown={modal}
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: classes.dialog,
      }}>
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" autoFocus>
          {onCancelLabel}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {onConfirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <div />
  );
};

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  notificationStore: PropTypes.object.isRequired,
};

const styles = theme => ({
  dialog: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
      minHeight: 250,
    },
  },
});
export default compose(
  inject('notificationStore'),
  withMobileDialog(),
  withStyles(styles),
  observer
)(ResponsiveDialog);
