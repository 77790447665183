//TODO improve comparaison cases on both methods

export function compareArrays(arr1, arr2) {
  if (arr1 && arr2) {
    const size1 = arr1.length;
    if (size1 !== arr2.length) return false;
    for (let index = 0; index < size1; index++) {
      if (arr1[index] !== arr2[index]) return false;
    }
    return true;
  } else {
    return false;
  }
}

export function compareMaps(map1, map2) {
  if (map1 && map2) {
    const size1 = map1.size;
    if (size1 !== map2.size) return false;
    const arr1 = Array.from(map1.values());
    const arr2 = Array.from(map2.values());
    for (let index = 0; index < size1; index++) {
      if (arr1[index] !== arr2[index]) return false;
    }
    return true;
  } else {
    return false;
  }
}
