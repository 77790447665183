/* eslint-disable react/display-name */
import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  TableBody,
  withStyles,
  Checkbox,
  Typography,
} from '@material-ui/core';
import TableRows from './TableRows';
import withSortingAndPagination from 'components/common/withSortingAndPagination';
import compose from 'utils/compose';
import TableToolbar from './TableToolbar';

const columns = [
  { id: 'actions', numeric: false, disablePadding: true, label: '', sortable: false },
  { id: 'alertNumber', numeric: false, disablePadding: false, label: 'N° fiche', sortable: true },
  {
    id: 'creationDate',
    numeric: false,
    label: 'Date Signalement',
    sortable: true,
  },
  { id: 'injuried', numeric: false, disablePadding: false, label: 'Blessés', sortable: true },
  { id: 'deceased', numeric: false, disablePadding: false, label: 'Décédés', sortable: true },
  { id: 'eventType#type', numeric: false, label: 'Typologie évmt.', sortable: true },
  { id: 'status', numeric: false, label: 'Status', sortable: true },
];

const generateHeader = (
  columns,
  { order, orderBy },
  numSelected,
  onRequestSort,
  onSelectAllClick,
  rowCount
) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            color="primary"
          />
        </TableCell>
        {columns.map(
          column => (
            <TableCell
              key={column.id}
              align={column.numeric ? 'right' : 'left'}
              padding="dense"
              // padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={column.sortable && orderBy === column.id ? order : false}>
              {column.sortable && (
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={onRequestSort(column.id)}>
                    <Typography variant="body1" color="inherit">
                      {column.label}
                    </Typography>
                  </TableSortLabel>
                </Tooltip>
              )}
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
};

const ResultTable = ({
  classes,
  allElements,
  sortedElements,
  sorting,
  onRequestSort,
  refresh,
  onExport,
  onExportAll,
}) => {
  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      setSelected(sortedElements.map(n => n.id));
    } else {
      setSelected([]);
    }
  };

  // React.useEffect(() => {
  //   setSelected(selected.filter(id => sortedElements.map(it => it.id).includes(id)));
  // }, [sortedElements]);

  const handleCheckboxClick = id => e => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleClickExport = e => {
    onExport(selected);
  };
  const handleClickExportAll = e => {
    onExportAll(allElements.map(n => n.id));
  };

  return (
    <div className={classes.tableWrapper}>
      <TableToolbar
        numSelected={selected.length}
        rowCount={allElements.length}
        onExport={handleClickExport}
        onExportAll={handleClickExportAll}
      />
      <Table className={classes.table}>
        <colgroup>
          <col style={{ width: '5px' }} />
          <col style={{ width: '50px' }} />
          <col style={{ width: '105px' }} />
          <col style={{ width: '160px' }} />
          <col style={{ width: '100px' }} />
          <col style={{ width: '100px' }} />
          <col style={{ width: '160px' }} />
          <col style={{ width: '90px' }} />
        </colgroup>
        {generateHeader(
          columns,
          sorting,
          selected.length, //numSelected
          onRequestSort,
          handleSelectAllClick,
          sortedElements.length //rowCount
        )}
        <TableBody>
          <TableRows
            selected={selected}
            data={sortedElements}
            onRowClick={handleCheckboxClick}
            refresh={refresh}
          />
        </TableBody>
      </Table>
    </div>
  );
};

const styles = theme => ({
  table: {
    minWidth: 900,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRow: {
    cursor: 'pointer',
  },
});

export default compose(withStyles(styles), React.memo, withSortingAndPagination)(ResultTable);
