import axios from 'axios';

export async function fetchDeclaration(id) {
  return axios.get(`/declarations/${id}`);
}

export async function updateDeclaration(id, values) {
  return axios.post(`/declarations/${id}/save`, values);
}

export async function postComment(declarationId, comment) {
  return axios.post(`/declarations/${declarationId}/comments`, {
    permanencier: comment.permanencier,
    message: comment.message,
    declaration: { id: comment.declaration.id, alertNumber: comment.declaration.alertNumber },
  });
}

export async function postPermanencier(declarationId, permanencier) {
  return axios.post(`/declarations/${declarationId}/editor`, permanencier);
}

export async function validateFiche(declarationId, permanencier) {
  return axios.post(`/declarations/${declarationId}/validate`, permanencier);
}

export async function archiveDeclaration(declarationId) {
  return axios.post(`/declarations/${declarationId}/archive`);
}

export async function closeDeclaration(declarationId) {
  return axios.post(`/declarations/${declarationId}/close`);
}
