import { CssBaseline, Link, Typography } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import AppToolBarDrawer from 'components/AppToolBarDrawer';
import ResponsiveDialog from 'components/common/Dialogs/ResponsiveDialog';
import AccountInfoPage from 'pages/AccountInfoPage';
import AdminAccountPage from 'pages/AdminAccountPage';
import DeclarationPage from 'pages/DeclarationPage';
import CreateDeclarationPage from 'pages/CreateDeclarationPage';
import HomePage from 'pages/HomePage';
import SearchPage from 'pages/SearchPage';
import React from 'react';
import { Route, Switch } from 'react-router';
import compose from 'utils/compose';
import SignInPage from 'pages/SignInPage';
import AuthorizedRoute from 'components/common/AuthorizedRoute';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import AccountCreatePage from 'pages/AccountCreatePage';
import { inject } from 'mobx-react';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      // disableRipple: true, // No more ripple, on the whole application 💣!
    },
    MuiInputLabel: {
      animated: null,
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        minHeight: 70,
      },
    },
    MuiFormControlLabel: {
      root: {
        '&$disabled': {
          // Augment text opacity when input is disabled
          color: 'rgba(0, 0, 0, .78)',
          // Use more explicit cursor when disabled
          cursor: 'no-drop',
        },
      },
      label: {
        '&$disabled': {
          // Augment text opacity when input is disabled
          color: 'rgba(0, 0, 0, .58)',
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        '&$disabled': {
          // Augment text opacity when input is disabled
          color: 'rgba(0, 0, 0, .58)',
        },
      },
      colorSecondary: {
        '&$disabled': {
          // Augment text opacity when input is disabled
          color: 'rgba(0, 0, 0, .58)',
        },
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '1rem',
      },
      body: {
        fontSize: '1rem',
      },
      sizeSmall: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    MuiTableRow: {
      root: {
        height: 40,
      },
    },
    MuiInputLabel: {
      shrink: {
        // Prevents labels from shrinking too much
        transform: 'translate(0, 1.5px) scale(0.85)',
      },
    },
    MuiInputBase: {
      input: {
        // Add padding on top of input
        padding: '8px 0 7px',
        '&$disabled': {
          // Augment text opacity when input is disabled
          color: 'rgba(0, 0, 0, .78)',
          // Use more explicit cursor when disabled
          cursor: 'no-drop',
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        // Augment label opacity
        color: 'rgba(0, 0, 0, 0.78)',
        '&$disabled': {
          // Augment text opacity when input label is disabled
          color: 'rgba(0, 0, 0, 0.58)',
        },
      },
      disabled: {},
      asterisk: {
        color: red[600],
        '&$error': {
          color: red[600],
        },
      },
    },
    MuiToolbar: {
      root: {
        width: '100%',
        margin: '0 auto',
        // maxWidth: '1000px',
      },
    },
    MuiSelect: {
      select: {
        '&$disabled': {
          // Augment text opacity when input is disabled
          color: 'rgba(0, 0, 0, .78)',
          // Use more explicit cursor when disabled
          cursor: 'no-drop',
        },
      },
    },
    MuiDialogContentText: {
      root: {
        fontSize: 20,
      },
    },
  },
});

const MainLayout = ({ classes }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <ResponsiveDialog />
      <AppToolBarDrawer className={'printHide'} />
      <div className={classes.pageContent}>
        <Switch>
          <Route exact path="/login" component={SignInPage} />
          <AuthorizedRoute exact path="/" component={HomePage} />
          <AuthorizedRoute
            exact
            path="/declaration"
            // render={props => {
            //   declarationStore.initStore();
            //   return <DeclarationPage {...props} />;
            // }}
            component={() => <CreateDeclarationPage />}
          />
          <AuthorizedRoute
            path="/declarations/:id?/:mode?"
            render={props => <DeclarationPage {...props} />}
          />
          <AuthorizedRoute
            path="/search"
            render={props => (
              <React.Fragment>
                <Breadcrumbs separator=">" aria-label="Breadcrumb">
                  <Link component={RouterLink} color="inherit" to="/">
                    Accueil
                  </Link>
                  <Typography color="textPrimary">Liste des signalements</Typography>
                </Breadcrumbs>
                <SearchPage {...props} />
              </React.Fragment>
            )}
          />
          <AuthorizedRoute
            roles={['NATIONAL']}
            exact
            path="/accounts"
            render={props => (
              <React.Fragment>
                <Breadcrumbs separator=">" aria-label="Breadcrumb">
                  <Link component={RouterLink} color="inherit" to="/">
                    Accueil
                  </Link>
                  <Typography color="textPrimary">Comptes</Typography>
                </Breadcrumbs>
                <AdminAccountPage {...props} />
              </React.Fragment>
            )}
          />
          <AuthorizedRoute
            roles={['NATIONAL']}
            exact
            path="/accounts/new"
            component={AccountCreatePage}
          />
          <AuthorizedRoute roles={['NATIONAL']} path="/accounts/:id?" component={AccountInfoPage} />
        </Switch>
      </div>
    </MuiThemeProvider>
  );
};

const styles = theme => ({
  pageContent: {
    width: 'auto',
    maxWidth: '960px',
    marginTop: '5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 10px',
  },
});

export default compose(inject('declarationStore'), withStyles(styles))(MainLayout);
