import get from 'lodash/get';
import french from 'i18n/french';

export function t(key, fallback = '') {
  return get(french, key, fallback);
}

export function formatMsg(key, args = {}, defaultMessage = '') {
  return format(get(french, key, defaultMessage), args);
}

function format(str, args) {
  for (let k in args) {
    str = str.replace(`{${k}}`, args[k]);
  }
  return str;
}
