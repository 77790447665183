import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import { version } from '../package.json';

const sentryUrl = process.env.REACT_APP_SENTRY_URL;
const sentryEnv = process.env.REACT_APP_SENTRY_ENV;
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: sentryUrl,
    release: version,
    environment: sentryEnv,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
