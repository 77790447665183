import {
  CircularProgress,
  Fab,
  Grid,
  Paper,
  withStyles,
  Typography,
  Link,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import axios from 'axios';
import AccountInfo from 'components/Admin/AccountInfo';
import accountInfoFormBuilder from 'components/Admin/accountInfoFormDataBuilder';
import { action, autorun, decorate, observable, transaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import React from 'react';
import compose from 'utils/compose';
import {
  FORM_MODE_CONSULT,
  FORM_MODE_EDIT,
  isConsultationMode,
  FORM_MODE_CREATE,
  isCreationMode,
} from 'utils/forms';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom';

class AccountInfoPage extends React.Component {
  promise;
  accountInfoForm;
  formData;
  mode;

  constructor(props) {
    super(props);
    const { id: accountId } = props.match.params;
    if (accountId && accountId !== 'new') {
      this.doFetchAccount(accountId).then(({ data }) =>
        transaction(() => {
          this.formData = data;
          this.mode = FORM_MODE_CONSULT;
        })
      );
    } else {
      transaction(() => {
        this.mode = FORM_MODE_CREATE;
        this.assignPromise(Promise.resolve({}));
      });
    }
    autorun(() => {
      this.accountInfoForm = accountInfoFormBuilder({
        initialValues: this.formData,
        mode: this.mode,
      });
    });
  }

  doFetchAccount = accountId => this.assignPromise(axios.get(`/admin/accounts/${accountId}`));

  doPostForm = (accountId, values) =>
    this.assignPromise(
      axios.post(`/admin/accounts${isCreationMode(this.mode) ? '' : `/${accountId}`}`, values)
    );

  assignPromise = action(fetch => {
    this.promise = observable(fromPromise(fetch));
    return this.promise;
  });

  handleClickEdit = action(() => {
    this.mode = FORM_MODE_EDIT;
  });

  handleSubmitForm = values => {
    const { id } = this.props.match.params;
    const { notificationStore } = this.props;
    return this.doPostForm(id, values).then(({ data }) =>
      transaction(() => {
        this.formData = data;
        this.mode = FORM_MODE_CONSULT;
        notificationStore.notifySuccess('Compte modifé');
      })
    );
  };

  handleCancel = action(() => {
    if (isCreationMode(this.mode)) {
      this.props.routingStore.replace('/accounts');
    } else {
      this.mode = FORM_MODE_CONSULT;
    }
  });

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Breadcrumbs separator=">" aria-label="Breadcrumb">
          <Link component={RouterLink} color="inherit" to="/">
            Accueil
          </Link>
          <Link component={RouterLink} color="inherit" to="/accounts">
            Comptes
          </Link>
          <Typography color="textPrimary">
            {this.formData && `${this.formData.firstname} ${this.formData.lastname}`}
          </Typography>
        </Breadcrumbs>
        <Grid container justify="center" alignItems="center" alignContent="center">
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {this.promise ? (
                this.promise.case({
                  pending: () => (
                    <Grid container justify="center">
                      <Grid item>
                        <CircularProgress variant="indeterminate" thickness={5} size={50} />
                      </Grid>
                    </Grid>
                  ),
                  fulfilled: ({ data = [] }) => (
                    <AccountInfo
                      form={this.accountInfoForm}
                      onSubmit={this.handleSubmitForm}
                      data={data}
                      mode={this.mode}
                      onCancel={this.handleCancel}
                    />
                  ),
                })
              ) : (
                <div />
              )}
            </Paper>
          </Grid>
          {isConsultationMode(this.mode) && (
            <Fab
              color="secondary"
              aria-label="Edit"
              className={classes.fab}
              onClick={this.handleClickEdit}>
              <Edit />
            </Fab>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    top: '80vh',
    right: '5vw',
  },
  paper: {
    minHeight: 510,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
});

export default compose(
  inject('notificationStore', 'routingStore'),
  withStyles(styles),
  observer
)(
  decorate(AccountInfoPage, {
    accountInfoForm: observable,
    formData: observable,
    mode: observable,
    promise: observable,
  })
);
