import {
  CircularProgress,
  Paper,
  Typography,
  withStyles,
  TextField,
  InputAdornment,
  Button,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import axios from 'axios';
import AccountTable from 'components/Admin/AccountTable';
import { decorate, observable, runInAction, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import React from 'react';
import compose from 'utils/compose';
import Search from '@material-ui/icons/Search';
import PersonAdd from '@material-ui/icons/PersonAdd';
import AccountFilters from 'components/Admin/AccountFilters';
import { Close } from '@material-ui/icons';

class AdminAccountPage extends React.Component {
  fetchAccounts;
  fiteredData;
  error = false;
  searchTerms = '';

  handleRowClick = accountId => {
    this.props.routingStore.push(`/accounts/${accountId}`);
  };

  handleCreateButton = () => {
    this.props.routingStore.push(`/accounts/new`);
  };

  handleChangeSearchbar = action(e => {
    this.searchTerms = e.target.value;
  });

  handleClickClearSearch = action(() => {
    this.searchTerms = '';
  });

  handleSearch = action(filters => {
    const { department } = filters;
    const terms = this.searchTerms && this.searchTerms.split(' ');
    const params = {};
    if (department && department.length) params.departments = department;
    if (terms && terms.length) params.terms = terms.join();
    this.fetchAccounts = fromPromise(
      axios.get('/admin/accounts', {
        params,
      })
    );
  });

  componentDidMount() {
    runInAction(() => (this.fetchAccounts = fromPromise(axios.get(`/admin/accounts`))));
  }

  componentDidCatch(err, info) {
    runInAction(() => {
      this.error = err;
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom align="left">
                Liste des comptes
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <TextField
                  fullWidth
                  label="Recherche"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  value={this.searchTerms}
                  onChange={this.handleChangeSearchbar}
                  className={classes.searchBar}
                />
                <Tooltip placement="bottom" title="Effacer">
                  <IconButton label="plop" size="small" onClick={this.handleClickClearSearch}>
                    <Close />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button variant="contained" color="primary" onClick={this.handleCreateButton}>
                <PersonAdd className={classes.leftIcon} />
                <Typography variant="button" color="inherit">
                  Créer un compte
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <AccountFilters onSearch={this.handleSearch} />
            </Grid>
          </Grid>
        </Paper>
        {this.fetchAccounts ? (
          this.fetchAccounts.case({
            rejected: () => (
              <div>
                <Typography variant="subtitle1" color="error">
                  Erreur de chargement
                </Typography>
              </div>
            ),
            pending: () => (
              <div className={classes.progressWrapper}>
                <CircularProgress variant="indeterminate" disableShrink thickness={5} size={50} />
              </div>
            ),
            fulfilled: ({ data = [] }) => (
              <Paper className={classes.paper}>
                <AccountTable
                  data={data}
                  defaultSortingColumn="department"
                  onRowClick={this.handleRowClick}
                />
              </Paper>
            ),
          })
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  progressWrapper: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBar: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  clickable: {
    cursor: 'pointer',
  },
});

export default compose(
  withStyles(styles),
  inject('routingStore'),
  observer
)(
  decorate(AdminAccountPage, {
    fetchAccounts: observable,
    filteredData: observable,
    error: observable,
    searchTerms: observable,
  })
);
