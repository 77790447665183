import { FormControl, FormHelperText, RadioGroup, FormLabel } from '@material-ui/core';
import React from 'react';

const RadioGroups = ({ id, disabled, label, error, fullWidth, helperText, required, ...props }) => (
  <FormControl
    fullWidth={fullWidth}
    error={error}
    required={required}
    disabled={disabled}
    component="fieldset">
    {label && (
      <FormLabel component="legend" disabled={disabled} htmlFor={id}>
        {label}
      </FormLabel>
    )}
    <RadioGroup disabled={disabled} {...props}>
      {props.children}
    </RadioGroup>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);

export default React.memo(RadioGroups);
