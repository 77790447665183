import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { formatDate } from 'utils/DateUtils';
import compose from 'utils/compose';

const CommentList = ({ classes, comments }) => {
  return (
    <Grid id="comments-list" container spacing={3} className={classes.container}>
      {comments &&
        comments.map((comment, i) => (
          <React.Fragment key={comment.id}>
            <Grid item xs={12} key={comment.id}>
              <Grid container alignItems="flex-start" justify="flex-start" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" noWrap>
                    {comment.permanencier.firstname} {comment.permanencier.lastname}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography noWrap>{formatDate(comment.date)}</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography>{comment.message}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
    </Grid>
  );
};
const styles = theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
});
export default compose(
  withStyles(styles),
  React.memo
)(CommentList);
