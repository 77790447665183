import React from 'react';
import LuxonUtils from '@date-io/luxon';
import { Grid } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers';

import withEditableForm from '../common/withEditableForm';
import { getInputFieldProps } from 'utils/forms';
import { observer } from 'mobx-react-lite';

// IANA Zone:  America/Port-au-Prince

const EventDate = observer(({ form }) => {
  const now = new Date();

  const handleChange = fields => value => {
    fields.forEach(field => {
      field.onChangeValue(value);
      field.markAsTouch();
    });
  };

  return (
    <MuiPickersUtilsProvider locale="fr" utils={LuxonUtils}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DatePicker
            id="date"
            name="date"
            label="Jour"
            placeholder="24/04/2019"
            format="dd/LL/yyyy"
            keyboard
            // minDate={now}
            maxDate={now}
            autoOk={true}
            invalidDateMessage="Le format de la date est invalide"
            adornmentPosition="end"
            keepCharPositions={true}
            mask={[/[0-2]/, /\d/, '/', /[0-2]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/]}
            clearable={false}
            {...getInputFieldProps(form.date)}
            onChange={handleChange([form.date])}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TimePicker
              id="hour"
              name="hour"
              label="Heure"
              placeholder="08:00 AM"
              format="hh:mm a"
              maxDate={now}
              keyboard
              ampm={true}
              mask={[/[0-1]/, /\d/, ':', /\d/, /\d/, ' ', /(a|p){1}/i, 'M']}
              disableOpenOnEnter
              {...getInputFieldProps(form.time)}
              onChange={handleChange([form.date, form.time])}
            />
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
});

export default withEditableForm(EventDate);
