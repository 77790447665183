import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, withStyles } from '@material-ui/core';
import Home from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import compose from 'utils/compose';
import clsx from 'clsx';

const AppToolbar = ({ classes, isAuthenticated, onClickLink, onClickMenu }) => {
  return (
    <AppBar position="fixed" className={clsx('printHide', classes.appBar)}>
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={onClickMenu}
          disabled={!isAuthenticated}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.grow}>
          Fiche de signalement
        </Typography>
        <IconButton color="inherit" onClick={onClickLink('/login')}>
          <Home />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

const styles = theme => ({
  appBar: {},
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  link: {
    textDecoration: 'none',
  },
});

export default compose(
  withStyles(styles),
  React.memo
)(AppToolbar);
