import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

const CircularLoader = ({ classes, color = 'secondary', ...props }) => {
  return (
    <div>
      <CircularProgress className={classes.progress} color={color} {...props} />
    </div>
  );
};

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});

export default React.memo(withStyles(styles)(CircularLoader));
