import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
} from 'utils/forms';
import { isRequired, isEmail } from 'utils/forms/validators';

export default function permanencierFormBuilder({ initialValues, mode = FORM_MODE_CREATE }) {
  const fields = {
    firstname: new Field({
      initValue: (initialValues && initialValues.firstname) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Prénom',
    }),
    lastname: new Field({
      initValue: (initialValues && initialValues.lastname) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Nom de famille',
    }),
    phone: new Field({
      initValue: (initialValues && initialValues.phone) || '',
      disabled: mode === FORM_MODE_CONSULT,
      label: 'Téléphone',
    }),
    email: new Field({
      initValue: (initialValues && initialValues.email) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isEmail],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Email',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
