import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import { DateTime } from 'luxon';

export function formatDate(dateStr) {
  if (dateStr && dateStr.length > 0) {
    const date = parseISO(dateStr);
    return format(date, 'dd-MM-yyyy hh:mm a');
  } else {
    return dateStr;
  }
}

export function dateValidOrConvertFromDateTime(dateStr, format = "yyyy-MM-dd'T'HH:mm:ssZZ") {
  const jsDate = DateTime.fromJSDate(dateStr);
  if (jsDate.isValid) {
    return jsDate.toFormat(format);
  } else {
    return dateStr.toFormat(format);
  }
}

// export function formatDate(dateStr) {
//   if (dateStr && dateStr.length > 0) {
//     console.debug(dateStr);
//     const date = DateTime.fromISO(dateStr);
//     console.debug(Settings.defaultZone);
//     console.debug(Settings.defaultZoneName);
//     console.debug(date.zoneName);
//     console.debug(date.toString());
//     console.debug(date.toLocaleString());
//     console.debug(date.toFormat('dd-MM-yyyy hh:mm a'));
//     return date.toFormat('dd-MM-yyyy hh:mm a');
//   } else {
//     return dateStr;
//   }
// }

// const { zonedTimeToUtc, utcToZonedTime, format } = require('date-fns-timezone')
