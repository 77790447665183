export const PERMANENCIER = 'PERMANENCIER';
export const ALERTED = 'ALERTED_PEOPLE';
export const SIGNALANT = 'SIGNALANT';
export const EVENT_DATE = 'EVENT_DATE';
export const EVENT_LOCATION = 'EVENT_LOCATION';
export const STRUCTURES = 'STRUCTURES';
export const EVENT_TYPE = 'EVENT_TYPE';
export const SERVICE = 'SERVICE';
export const INJURIED = 'INJURIED';
export const DECEASED = 'DECEASED';
export const EVENT_DESCRIPTION = 'EVENT_DESCRIPTION';
export const ACTIONS = 'ACTIONS';
export const COMMENTS = 'COMMENTS';
export const ALERT_NUMBER = 'ALERT_NUMBER';
export const DEPARTMENT = 'DEPARTMENT';

export const departments = ['AR', 'CE', 'GA', 'NI', 'ND', 'NE', 'NO', 'OU', 'SD', 'SE'];
export const eventTypes = [
  'ACC',
  // 'MAN',
  'MVF',
  // 'TIR',
  'FUS',
  'INC',
  'MVT',
  // 'SEI',
  'TRT',
  'CYC',
  'INO',
  'EFB',
  'TSU',
  'ITX',
  // 'CHO',
  'DAA',
  'DAS',
  'ROU',
  // 'PAL',
  'MEN',
  'DEN',
  // 'ZIK',
  // 'CIK',
  'DIP',
  'OTH',
];

export const statuses = ['DRAFT', 'VALIDATED', 'CLOSED'];

export const REFRESH_SESSION_INTERVAL = 1000 * 60 * 5;
