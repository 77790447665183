import { action, computed, decorate, observable } from 'mobx';
// import User from './User';
import axios from 'axios';
import { registerAxiosInterceptors } from 'utils/axios';

class AuthenticationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    registerAxiosInterceptors(this.onUnauthenticated, this.onUnauthorized, this.onUnreachable);
    // this.sessionRefresh();
  }
  _user;
  _authenticated = false;
  _ready = true;
  _firstTry = true;

  get isAuthenticated() {
    return this._authenticated;
  }
  get user() {
    return this._user;
  }
  get isReady() {
    return this._ready;
  }

  authenticate = async (username, password) => {
    this._ready = false;
    return axios
      .post(`/auth/signin`, {
        username: username,
        password: password,
      })
      .then(
        action(res => {
          this._authenticated = true;
          this._user = res.data;
          this._ready = true;

          this.rootStore.notificationStore.notifySuccess('Connexion réussie');
          return res;
        })
      )
      .catch(err => {
        console.error(err);
      })
      .finally(
        action(() => {
          if (this._firstTry) this._firstTry = false;
        })
      );
  };

  disconnect = async () => {
    axios
      .get(`/auth/signout`)
      .catch(err => {
        console.error(err);
        this.rootStore.notificationStore.notifyError('Échec de la déconnexion');
      })
      .then(
        action(() => {
          this._authenticated = false;
          this._user = undefined;
        })
      );
    // this.rootStore.routingStore.push('/login');
  };

  setUser = data => {
    this._user = data;
  };

  sessionRefresh = () => {
    this._ready = false;
    console.debug('sessionRefresh');
    axios.get(`/auth/session`).then(
      action(res => {
        this._authenticated = true;
        this._user = res.data;
        this._ready = true;
      })
    );
  };

  onUnauthenticated = () => {
    if (!this._user) {
      if (!this._firstTry) {
        this.rootStore.notificationStore.notifyError("Échec de l'authentification");
      }
    } else {
      this.rootStore.notificationStore.notifyError('Session expirée');
    }
    this._authenticated = false;
    this._user = undefined;
    this._ready = true;
  };

  onUnauthorized = () => {
    // if (this._user) {
    this.rootStore.notificationStore.notifyError('Accès non autorisé');
    // }
    // Redirect to Home page
    try {
      this.rootStore.routingStore.replace('/');
    } catch (err) {
      console.error(err);
    }
  };

  onUnreachable = error => {
    this.rootStore.notificationStore.notifyError('Le serveur ne semble pas joignable');
  };

  get isNational() {
    return this._user && this._user.role === 'NATIONAL';
  }

  matchDepartment = department => {
    return (
      !!department &&
      this._user &&
      this._user.role === 'DEPARTMENTAL' &&
      this._user.department === department
    );
  };
}

export default decorate(AuthenticationStore, {
  _user: observable,
  _authenticated: observable,
  _ready: observable,
  user: computed,
  isAuthenticated: computed,
  isReady: computed,
  authenticate: action,
  disconnect: action,
  setUser: action,
  onUnauthenticated: action,
  isNational: computed,
  sessionRefresh: action,
  _firstTry: observable,
});
