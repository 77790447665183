import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField } from '@material-ui/core';
import { getInputFieldProps } from 'utils/forms';
import compose from 'utils/compose';
import { observer } from 'mobx-react-lite';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

function LoginForm({ classes, form, onSubmit }) {
  const handleSubmit = e => {
    e.preventDefault();
    console.debug('LoginForm submit');
    console.debug(form.isValid);
    form.validate();
    if (form.isValid) onSubmit(form.values);
  };
  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Authentification
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            required
            fullWidth
            margin="normal"
            label="Nom d'utilisateur"
            autoComplete="username"
            name="username"
            id="username"
            autoFocus
            {...getInputFieldProps(form.username)}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            label="Mot de passe"
            autoComplete="password"
            name="password"
            id="password"
            type="password"
            {...getInputFieldProps(form.password)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>
            Se connecter
          </Button>
        </form>
      </Paper>
    </main>
  );
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

export default compose(
  withStyles(styles),
  observer
)(LoginForm);
