import { Collapse, withStyles, MenuItem, Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Email, Phone } from '@material-ui/icons';
import React from 'react';
import compose from 'utils/compose';
import { getInputFieldProps } from 'utils/forms';
import withEditableForm from 'components/form/common/withEditableForm';
import Selects from 'components/form/common/Selects';
import { observer } from 'mobx-react-lite';

const AccountInfo = ({ classes, form }) => {
  const [editPassword] = React.useState(false);

  return (
    <React.Fragment>
      <Typography variant="subtitle1" className={classes.subTitle} gutterBottom align="left">
        Paramètres
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="username"
            name="username"
            label="Nom d'utilisateur"
            fullWidth
            autoComplete="username"
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              className: classes.input,
            }}
            {...getInputFieldProps(form.username)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="password"
            name="password"
            label="Mot de passe"
            fullWidth
            autoComplete="current-password"
            InputProps={{
              type: 'password',
            }}
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              className: classes.input,
            }}
            {...getInputFieldProps(form.password)}
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={editPassword}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Hidden xsDown>
                  <div />
                </Hidden>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="password-confirm"
                  name="password"
                  label="Confirmer mot de passe"
                  fullWidth
                  autoComplete="new-password"
                  InputProps={{
                    type: 'password',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  FormHelperTextProps={{
                    className: classes.input,
                  }}
                  {...getInputFieldProps(form.password)}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Selects
            required
            fullWidth
            // shrink={true}
            label="Niveau compte"
            placeholder="Niveau compte"
            {...getInputFieldProps(form.role)}>
            <MenuItem value={'NATIONAL'}>Direction Nationale</MenuItem>
            <MenuItem value={'DEPARTMENTAL'}>Direction Departemental</MenuItem>
          </Selects>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={form.role.value === 'DEPARTMENTAL'}>
            <Selects
              required
              fullWidth
              label="Département"
              placeholder="Département"
              {...getInputFieldProps(form.department)}>
              <MenuItem value={'AR'}>Artibonite</MenuItem>
              <MenuItem value={'CE'}>Centre</MenuItem>
              <MenuItem value={'GA'}>Grand'Anse</MenuItem>
              <MenuItem value={'NI'}>Nippes</MenuItem>
              <MenuItem value={'ND'}>Nord</MenuItem>
              <MenuItem value={'NE'}>Nord Est</MenuItem>
              <MenuItem value={'NO'}>Nord Ouest</MenuItem>
              <MenuItem value={'OU'}>Ouest</MenuItem>
              <MenuItem value={'SD'}>Sud</MenuItem>
              <MenuItem value={'SE'}>Sud-Est</MenuItem>
            </Selects>
          </Collapse>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" className={classes.subTitle} gutterBottom align="left">
        Identité
      </Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="firstName"
            name="firstName"
            label="Prénom"
            required
            fullWidth
            autoComplete="given-name"
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              className: classes.input,
            }}
            {...getInputFieldProps(form.firstname)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="lastName"
            name="lastName"
            label="Nom de famille"
            required
            fullWidth
            autoComplete="family-name"
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              className: classes.input,
            }}
            {...getInputFieldProps(form.lastname)}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" className={classes.subTitle} gutterBottom align="left">
        Coordonnées
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="phone"
            name="phone"
            label="Phone number"
            icon="phone"
            fullWidth
            autoComplete="tel-national"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              disableAnimation: true,
            }}
            {...getInputFieldProps(form.phone)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            {...getInputFieldProps(form.email)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({
  subTitle: {
    fontSize: '14px',
    marginTop: 20,
    fontWeight: 700,
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
  },
});

export default compose(
  withStyles(styles),
  withEditableForm,
  observer
)(AccountInfo);
