import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Checkbox,
  ListItemText,
  MenuItem,
  withStyles,
  Input,
  Select,
} from '@material-ui/core';
import React from 'react';
import compose from 'utils/compose';
import Divider from '@material-ui/core/Divider';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const selectStyles = theme => ({
  input: base => ({
    ...base,
    color: theme.palette.text.primary,
    '& input': {
      font: 'inherit',
    },
  }),
  iconLeft: {
    marginRight: theme.spacing(1),
  },
  formControl: {},
});

const displaySelectedValues = options => selected => {
  if (selected.length === 1) {
    return options.find(({ value, label }) => value === selected[0]).label;
  } else {
    return `${selected.length} options sélectionnées`;
  }
};

const SelectCheckboxes = ({
  classes,
  id,
  disabled,
  label,
  error,
  fullWidth,
  helperText,
  required,
  shrink,
  onChangeCallback,
  onSelectAll,
  onUnselectAll,
  options,
  ...props
}) => {
  const allSelected = props.value.length === options.length;
  return (
    <FormControl
      fullWidth={fullWidth}
      error={error}
      required={required}
      className={classes.formControl}>
      <InputLabel disabled={disabled} shrink={shrink || !!props.value} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        multiple
        input={<Input id={id} />}
        renderValue={displaySelectedValues(options)}
        {...props}>
        <div tabIndex={-1} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button onClick={onSelectAll} color={allSelected ? 'primary' : 'default'}>
            <CheckBoxIcon className={classes.iconLeft} />
            Tous
          </Button>
          <Button onClick={onUnselectAll} color={props.value.length === 0 ? 'primary' : 'default'}>
            <CheckBoxOutlineBlankIcon className={classes.iconLeft} />
            Aucun
          </Button>
        </div>
        <Divider />
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={props && props.value.indexOf(value) !== -1} color="primary" />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default compose(
  React.memo,
  withStyles(selectStyles)
)(SelectCheckboxes);
