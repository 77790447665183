import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { getInputFieldProps } from 'utils/forms';
import withEditableForm from '../common/withEditableForm';

const EventDescription = observer(({ form }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="event_description"
            name="event_description"
            fullWidth
            placeholder="Détails de l’événement tel que transmis par le signalant"
            multiline={true}
            rows={3}
            variant="outlined"
            {...getInputFieldProps(form.description)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default withEditableForm(EventDescription);
