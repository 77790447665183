import { Grid, TextField } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import compose from 'utils/compose';
import { getInputFieldProps } from 'utils/forms';
import withEditableForm from '../common/withEditableForm';

const CommentsForm = ({ form, comments }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="comment"
            name="comment"
            fullWidth
            margin="normal"
            multiline={true}
            rows={3}
            variant="outlined"
            placeholder="Saisie libre"
            // label="Commentaires"
            {...getInputFieldProps(form.comment)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default compose(
  withEditableForm,
  observer
)(CommentsForm);
