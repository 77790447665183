import React from 'react';
import { inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { LoginForm, loginFormFormBuilder } from 'components/Login';
import compose from 'utils/compose';
import { observer } from 'mobx-react-lite';

function SignInPage({ authenticationStore, location }) {
  const { isReady, isAuthenticated, authenticate } = authenticationStore;
  const loginForm = loginFormFormBuilder({});
  const handleSubmit = async ({ username, password }) => {
    return authenticate(username, password);
  };
  // console.debug('location.state:');
  // console.debug(location.state);
  // console.debug('isReady: ' + isReady);
  // console.debug('isAuthenticated: ' + isAuthenticated);
  if (isReady && isAuthenticated) {
    const redirectTo =
      location.state && !!location.state.from && location.state.from.pathname !== '/login'
        ? location.state.from.pathname
        : '/';
    console.debug('redirectTo: ' + redirectTo);
    return <Redirect to={redirectTo} />;
  } else {
    return (
      <div>
        <LoginForm form={loginForm} onSubmit={handleSubmit} />
      </div>
    );
  }
}

export default compose(
  inject('authenticationStore'),
  observer
)(SignInPage);
