import { CircularProgress, Hidden, Paper, Typography, withStyles } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import axios from 'axios';
import FilterBloc from 'components/Search/FilterBloc';
import ResultListMobile from 'components/Search/ResultListMobile';
import ResultTable from 'components/Search/ResultTable';
import { action, decorate, observable, autorun } from 'mobx';
import { observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import React from 'react';
import compose from 'utils/compose';
import fileDownload from 'js-file-download';

function transformForSorting(value) {
  if (!isNaN(value)) {
    // convert text to number in order to allow proper sorting
    return parseInt(value);
  }
  switch (value) {
    case 'I':
      return -1;
    case 'N':
      return 0;
    case 'A':
      return 9.5;
    case 'B':
      return 49.5;
    case 'C':
      return 99.5;
    case 'D':
      return 100.5;
    default:
      return null;
  }
}

class SearchPage extends React.Component {
  fetchDeclarations;

  fetch = action(() => {
    this.fetchDeclarations = fromPromise(axios.get(`/declarations/`));
  });

  handleSearch = action(filters => {
    const { eventType, department, status } = filters;
    const params = {};
    if (eventType.length) params.types = eventType;
    if (department.length) params.departments = department;
    if (status.length) params.statuses = status;
    this.fetchDeclarations = fromPromise(
      axios.get('/declarations', {
        params,
      })
    );
  });

  handleExport = selected => {
    return axios
      .post(
        '/declarations/export',
        { declarations: selected },
        {
          responseType: 'blob',
        }
      )
      .then(res => {
        const fileName = res.headers['x-filename'];
        fileDownload(res.data, fileName, 'text/csv');
      });
  };

  handleExportAll = selected => {
    return axios
      .post(
        '/declarations/export',
        { declarations: selected },
        {
          responseType: 'blob',
        }
      )
      .then(res => {
        const fileName = res.headers['x-filename'];
        fileDownload(res.data, fileName, 'text/csv');
      });
  };

  handleClick = e => {
    e.preventDefault();
  };

  componentDidMount() {
    this.fetch();
    autorun(() => {
      this.fetchDeclarations.then(
        action(res => {
          this.results = res.data;
        })
      );
    });
  }

  render() {
    const { classes, width } = this.props;
    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <FilterBloc onSearch={this.handleSearch} />
          {/* {this.results && (
            <Typography variant="h6" color="textSecondary">
              {this.results.length} résultats trouvés:
            </Typography>
          )} */}
        </Paper>
        {isWidthDown('xs', width) &&
          this.fetchDeclarations &&
          this.fetchDeclarations.case({
            fulfilled: ({ data = [] }) => (
              <ResultListMobile classes={classes} data={data} handleClick={this.handleClick} />
            ),
          })}
        <Hidden only="xs">
          <Paper className={classes.paper}>
            {this.fetchDeclarations ? (
              this.fetchDeclarations.case({
                rejected: () => (
                  <div>
                    <Typography variant="subtitle1" color="error">
                      Erreur de chargement
                    </Typography>
                  </div>
                ),
                pending: () => (
                  <div className={classes.progressWrapper}>
                    <CircularProgress
                      variant="indeterminate"
                      disableShrink
                      thickness={5}
                      size={50}
                    />
                  </div>
                ),
                fulfilled: ({ data = [] }) => {
                  return (
                    <ResultTable
                      data={data}
                      specificSorting={{
                        deceased: transformForSorting,
                        injuried: transformForSorting,
                      }}
                      defaultSortingColumn={'creationDate'}
                      defaultSortingDirection="desc"
                      refresh={this.fetch}
                      onExport={this.handleExport}
                      onExportAll={this.handleExportAll}
                    />
                  );
                },
              })
            ) : (
              <div />
            )}
          </Paper>
        </Hidden>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  searchBar: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  progressWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButton: {
    fontSize: theme.typography.pxToRem(32),
  },
});

export default compose(
  withWidth(),
  withStyles(styles),
  observer
)(
  decorate(SearchPage, {
    fetchDeclarations: observable,
    results: observable,
  })
);
