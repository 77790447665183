import { decorate, action, observable, reaction } from 'mobx';

class NotificationStore {
  notifications = observable.array([], { deep: false });
  dialog;
  constructor() {
    reaction(() => this.notifications.length, () => this.notifications.shift(), { delay: 6000 });
  }

  notifyError = message => {
    console.debug('Notifying error');
    this.pushNotification(message, 'error');
  };

  notifySuccess = message => {
    console.debug('Notifying success');
    this.pushNotification(message, 'success');
  };

  pushNotification = action((message, type) => {
    this.notifications.push({ message, type });
  });

  showDialog = action(({ title, message, onConfirm, onCancel, onConfirmLabel, onCancelLabel }) => {
    this.dialog = { title, message, onConfirm, onCancel, onConfirmLabel, onCancelLabel };
  });

  hideDialog = action(() => {
    this.dialog = undefined;
  });
}

export default decorate(NotificationStore, {
  dialog: observable,
  notifications: observable,
  pushNotification: action,
});
