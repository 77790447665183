import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
} from 'utils/forms';
import { isRequired } from 'utils/forms/validators';

export default function eventLocationFormBuilder({
  initialValues = null,
  mode = FORM_MODE_CREATE,
}) {
  const fields = {
    // department: new Field({
    //   initValue: (initialValues && initialValues.department) || undefined,
    //   disabled: mode === FORM_MODE_CONSULT,
    //   validate: [isRequired],
    //   validateOn: [FORM_VALID_ON_BLUR],
    //   label: '',
    // }),
    address: new Field({
      initValue: (initialValues && initialValues.address) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    description: new Field({
      initValue: (initialValues && initialValues.description) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    descriptionOther: new Field({
      initValue: (initialValues && initialValues.address) || '',
      validate: [
        (value, label) => {
          return fields.description.value === 'OTH'
            ? isRequired(value, label)
            : () => ({
                valid: true,
              });
        },
      ],
      validateOn: [FORM_VALID_ON_BLUR],
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    accessibility: new Field({
      initValue: (initialValues && initialValues.accessibility) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
