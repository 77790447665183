import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, withStyles } from '@material-ui/core';
import { AddAlert, Search, SupervisorAccount, PersonOutline } from '@material-ui/icons';

import clsx from 'clsx';
import compose from 'utils';

const AppMenuDrawer = ({
  classes,
  handleDrawer,
  isAuthenticated,
  isCurrentPath,
  isNational,
  isMenuOpen = false,
  onClickLink,
  onDisconnect,
}) => {
  const sideList = (
    <div className={clsx('printHide', classes.list)}>
      <List>
        <div onClick={onClickLink('/declaration')} className={classes.link}>
          <ListItem button key={'Signalement'} disabled={isCurrentPath('/declaration')}>
            <ListItemIcon>
              <AddAlert />
            </ListItemIcon>
            <ListItemText primary={'Faire un signalement'} />
          </ListItem>
        </div>

        <div onClick={onClickLink('/search')} className={classes.link}>
          <ListItem button key={'Signalements'} disabled={isCurrentPath('/search')}>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText primary={'Liste des signalements'} />
          </ListItem>
        </div>
        {isNational && (
          <div onClick={onClickLink('/accounts')} className={classes.link}>
            <ListItem button key={'accounts'} disabled={isCurrentPath('/accounts')}>
              <ListItemIcon>
                <SupervisorAccount />
              </ListItemIcon>
              <ListItemText primary={'Gestion des comptes'} />
            </ListItem>
          </div>
        )}
        <div onClick={onDisconnect} className={classes.link}>
          <ListItem button key={'logout'} disabled={!isAuthenticated}>
            <ListItemIcon>
              <PersonOutline />
            </ListItemIcon>
            <ListItemText primary={'Déconnexion'} />
          </ListItem>
        </div>
      </List>
    </div>
  );

  return (
    <Drawer anchor="left" open={isMenuOpen} onClose={handleDrawer(false)}>
      <div tabIndex={0} role="button" onClick={handleDrawer(false)} onKeyDown={handleDrawer(false)}>
        {sideList}
      </div>
    </Drawer>
  );
};

const styles = theme => ({
  list: {
    width: 260,
  },
  fullList: {
    width: 'auto',
  },
  link: {
    textDecoration: 'none',
  },
});

export default compose(
  withStyles(styles),
  React.memo
)(AppMenuDrawer);
