import React from 'react';
import { Grid, Button, withStyles, Typography } from '@material-ui/core';
import { AddAlert, Search } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

const HomePage = ({ classes }) => {
  return (
    <div className={classes.container}>
      <Grid container direction="row" justify="center" spacing={5}>
        <Grid id="plop" item xs={11} sm={7}>
          <Button
            className={classes.button}
            fullWidth
            variant="contained"
            color="secondary"
            size="large"
            component={RouterLink}
            to="/declaration">
            <AddAlert fontSize="large" className={classes.leftIcon} />
            <Typography color="inherit" noWrap>
              Déclarer un signalement
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={11} sm={7}>
          <Button
            className={classes.button}
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            component={RouterLink}
            to="/search">
            <Search fontSize="large" className={classes.leftIcon} />
            <Typography color="inherit" noWrap inline>
              Rechercher un signalement
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = theme => ({
  container: {
    marginTop: '2rem',
  },
  button: {
    height: '4rem',
  },
  leftIcon: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
});
export default withStyles(styles)(HomePage);
