import React from 'react';
import { Grid, MenuItem, Input, TextField, Collapse } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { getInputFieldProps } from 'utils/forms';
import Selects from 'components/form/common/Selects';
import withEditableForm from '../common/withEditableForm';
import compose from 'utils/compose';
import { eventTypes } from 'constants/index';
import { t } from 'utils/i18n';

const EventType = ({ form }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Selects
          fullWidth
          label="Type d'événement"
          placeholder="Type d'événement"
          input={<Input name="event-type" />}
          {...getInputFieldProps(form.type)}>
          {eventTypes.map((v, i) => (
            <MenuItem key={`${i}-${v}`} value={v}>
              {t(`declaration.eventTypes.${v}`, v)}
            </MenuItem>
          ))}
        </Selects>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={form.type.value === 'OTH'}>
          <TextField
            required
            placeholder="Autre type d'événement"
            fullWidth
            margin="normal"
            {...getInputFieldProps(form.other)}
          />
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default compose(withEditableForm, observer)(EventType);
