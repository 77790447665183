import React from 'react';
import { TableRow, TableCell, Typography, withStyles, Checkbox } from '@material-ui/core';
import { formatDate } from 'utils/DateUtils';
import { t } from 'utils/i18n';
import TableRowMenu from './TableRowMenu';
import compose from 'utils/compose';
import { inject } from 'mobx-react';
import Edit from '@material-ui/icons/Edit';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import Archive from '@material-ui/icons/Archive';
import { CloudDownload } from '@material-ui/icons';
import { closeDeclaration } from 'services/DeclarationService';

const TableRows = ({
  classes,
  data,
  authenticationStore,
  notificationStore,
  onRowClick,
  routingStore,
  refresh,
  selected,
}) => {
  const isSelected = id => selected.indexOf(id) !== -1;

  if (data && data.length)
    return data.map(n => {
      const rowSelected = isSelected(n.id);
      return (
        <TableRow
          hover
          key={n.id}
          role="checkbox"
          tabIndex={-1}
          selected={rowSelected}
          className={classes.tableRows}>
          <TableCell padding="none">
            <Checkbox checked={rowSelected} color="primary" onClick={onRowClick(n.id)} />
          </TableCell>
          <TableCell padding="none" align="right">
            <TableRowMenu
              menuOptions={[
                {
                  key: 'consult',
                  name: 'Consulter',
                  icon: RemoveRedEye,
                  visible: true,
                  onClick: e => routingStore.push(`/declarations/${n.id}`),
                },
                {
                  key: 'edit',
                  name: 'Modifier',
                  disabled: n.status === 'CLOSED' && authenticationStore.user.status !== 'NATIONAL',
                  visible: true,
                  icon: Edit,
                  onClick: event => routingStore.push(`/declarations/${n.id}/edit`),
                },
                {
                  key: 'archive',
                  name: 'cloturer',
                  icon: Archive,
                  color: 'secondary',
                  disabled: n.status !== 'VALIDATED',
                  visible: true,
                  onClick: e => {
                    notificationStore.showDialog({
                      title: 'Clôturer',
                      message: 'Confirmer la clôture de la fiche ?',
                      onConfirm: () => {
                        closeDeclaration(n.id)
                          .catch(err => {
                            console.error(err);
                            notificationStore.notifyError('Echec de la clôture');
                          })
                          .then(() => {
                            refresh();
                            notificationStore.notifySuccess('Clôture effectuée');
                          });
                      },
                    });
                  },
                },
                {
                  key: 'exporter',
                  name: 'Exporter',
                  icon: CloudDownload,
                  color: 'disabled',
                  disabled: true || n.status !== 'VALIDATED',
                  visible: true,
                  // onClick: (e, callback) => {
                  // notificationStore.showDialog({
                  //   title: 'Exporter',
                  //   message: "Confirmer l'archivage ?",
                  //   onConfirm: () => null,
                  // });
                  // callback();
                  // },
                },
              ]}
            />
          </TableCell>
          <TableCell padding="dense" align="left">
            <Typography variant="body2">{n.alertNumber}</Typography>
          </TableCell>
          <TableCell padding="dense" align="left">
            <Typography variant="body2">{formatDate(n.creationDate)}</Typography>
          </TableCell>
          <TableCell padding="dense" align="right">
            <Typography variant="body2">
              {n.injuried ? t(`declaration.injuried.${n.injuried}`, n.injuried) : '-'}
            </Typography>
          </TableCell>
          <TableCell padding="dense" align="right">
            <Typography variant="body2">
              {n.deceased ? t(`declaration.deceased.${n.deceased}`, n.deceased) : '-'}
            </Typography>
          </TableCell>
          <TableCell padding="dense" align="left">
            <Typography variant="body2">
              {n.eventType
                ? t(`declaration.eventTypes.${n.eventType.type}`, n.eventType.type)
                : '-'}
            </Typography>
          </TableCell>
          <TableCell padding="dense" align="left">
            <Typography variant="body2">
              {n.status ? t(`declaration.status.${n.status}`, n.status) : '-'}
            </Typography>
          </TableCell>
        </TableRow>
      );
    });
  else
    return (
      <TableRow hover aria-checked={false} tabIndex={-1} elected={false}>
        <TableCell padding="dense" align="left" colSpan={6}>
          <Typography variant="subtitle1" inline>
            Aucun résultat...
          </Typography>
        </TableCell>
      </TableRow>
    );
};

const styles = {
  tableRows: {
    // cursor: 'pointer',
  },
};

export default compose(
  React.memo,
  inject('authenticationStore', 'notificationStore', 'routingStore'),
  withStyles(styles)
)(TableRows);
