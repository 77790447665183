import compose from 'utils/compose';
import React from 'react';
import classNames from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { Button, Menu, MenuItem } from '@material-ui/core';

const TableToolbar = ({ numSelected, classes, rowCount = 0, onExport, onExportAll }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickExport = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMenuItem = exportFn => e => {
    handleClose();
    exportFn();
  };
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} élément(s) sélectionné(s)
          </Typography>
        ) : (
          <Typography variant="h6" color="textSecondary">
            {rowCount} résultats trouvés:
          </Typography>
        )
        // (
        //   <Typography variant="h6" id="tableTitle">
        //     Signalements
        //   </Typography>
        // )
        }
      </div>
      <div className={classes.spacer} />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Exporter">
            <Button
              aria-label="Exporter"
              color="primary"
              className={classes.actions}
              onClick={handleClickExport}>
              <Typography variant="body1" color="inherit" inline>
                Exporter
              </Typography>
              <CloudDownloadIcon className={classes.actionIcon} />
            </Button>
          </Tooltip>
        ) : (
          <div />
          // <Tooltip title="Filter list">
          //   <IconButton aria-label="Filter list">
          //     <FilterList />
          //   </IconButton>
          // </Tooltip>
        )}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem className={classes.menuItem} onClick={handleClickMenuItem(onExport)}>
          <Typography variant="button">Sélectionnés</Typography>

          <DoneIcon />
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleClickMenuItem(onExportAll)}>
          <Typography variant="button">Tous</Typography>
          <DoneAllIcon />
        </MenuItem>
      </Menu>
    </Toolbar>
  );
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.primary.main,
  },
  title: {
    flex: '0 0 auto',
  },
  actionIcon: {
    marginLeft: theme.spacing(1),
  },
  menuItem: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default compose(withStyles(toolbarStyles), React.memo)(TableToolbar);
