import React from 'react';
import { Button, Grid, withStyles } from '@material-ui/core';
import { departments } from 'constants/index';
import SearchIcon from '@material-ui/icons/Search';
import { t } from 'utils/i18n';
import compose from 'utils/compose';
import SelectCheckboxes from 'components/form/common/SelectCheckboxes';

const AccountFilters = ({ onSearch }) => {
  const [department, setDepartment] = React.useState([]);
  const updaters = {
    department: setDepartment,
  };

  const handleSelectChange = key => evt => {
    updaters[key](evt.target.value);
  };

  const handleClickSearch = e => {
    const filters = {
      department: department.join(),
    };
    return onSearch(filters);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {/* <Grid item xs={4} md={3}>
            <SelectCheckboxes
              label="Départements"
              fullWidth
              value={department}
              onChange={handleSelectChange('department')}
              options={departments.map(e => {
                return { value: e, label: t(`departments.${e}`, e) };
              })}
            />
          </Grid> */}
          <Grid item xs={4} md={3}>
            <SelectCheckboxes
              label="Départements"
              fullWidth
              value={department}
              onChange={handleSelectChange('department')}
              options={departments.map(e => {
                return { value: e, label: t(`departments.${e}`, e) };
              })}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleClickSearch}>
            <SearchIcon />
            Rechercher
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  // searchBar: {
  //   width: '100%',
  //   [theme.breakpoints.up('sm')]: {
  //     width: '80%',
  //   },
  // },
  iconButton: {
    fontSize: theme.typography.pxToRem(32),
  },
});

export default compose(
  withStyles(styles),
  React.memo
)(AccountFilters);
