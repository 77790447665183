import {
  Table,
  TableHead,
  TableRow,
  withStyles,
  TableSortLabel,
  Tooltip,
  TableCell,
  TableBody,
  Typography,
  withTheme,
} from '@material-ui/core';
import React from 'react';
import compose from 'utils/compose';
import { formatDate } from 'utils/DateUtils';
import withSortingAndPagination from 'components/common/withSortingAndPagination';
import { t } from 'utils/i18n';

const columns = [
  { id: 'firstname', numeric: false, disablePadding: true, label: 'Prénom' },
  { id: 'lastname', numeric: false, disablePadding: true, label: 'Nom' },
  { id: 'department', numeric: false, disablePadding: true, label: 'Département' },
  { id: 'role', numeric: false, disablePadding: true, label: 'Niveau compte' },
  { id: 'lastModificationDate', numeric: true, disablePadding: true, label: 'Dernière modif.' },
];

const generateHeader = (columns, { order, orderBy }, onRequestSort) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map(
          column => (
            <TableCell
              key={column.id}
              align={column.numeric ? 'right' : 'left'}
              padding="dense"
              // padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.id ? order : false}>
              <Tooltip
                title="Sort"
                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}>
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={onRequestSort(column.id)}>
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
};

// const duplicate = (x, n) => Array.from(new Array(n), () => x);

const AccountTable = ({
  classes,
  theme,
  emptyRows,
  sortedElements,
  sorting,
  onRequestSort,
  onRowClick,
}) => {
  const handleClick = id => event => {
    onRowClick(id);
  };
  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.table}>
        {generateHeader(columns, sorting, onRequestSort)}
        <TableBody>
          {sortedElements.map(n => {
            return (
              <TableRow
                hover
                onClick={handleClick(n.id)}
                key={n.id}
                className={classes.tableRow}
                // aria-checked={isSelected}
                // selected={isSelected}
              >
                <TableCell component="th" scope="row" padding="dense">
                  <Typography variant="body2">{n.firstname}</Typography>
                </TableCell>
                <TableCell component="th" scope="row" padding="dense">
                  <Typography variant="body2">{n.lastname}</Typography>
                </TableCell>
                <TableCell align="left" padding="dense">
                  <Typography variant="body2">
                    {n.department ? t(`departments.${n.department}`, n.department) : '-'}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row" padding="dense">
                  <Typography variant="body2">{t(`account.role.${n.role}`, n.role)}</Typography>
                </TableCell>
                <TableCell align="right" padding="dense">
                  <Typography variant="body2">{formatDate(n.lastModificationDate)}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
          {(!sortedElements || sortedElements.length === 0) && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Typography variant="subtitle1">Aucun résultat</Typography>
              </TableCell>
            </TableRow>
          )}
          {sortedElements && sortedElements.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: theme.overrides.MuiTableRow.root.height * emptyRows }}>
              <TableCell colSpan={columns.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const styles = theme => ({
  table: {
    minWidth: 800,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRow: {
    cursor: 'pointer',
  },
});

export default compose(
  React.memo,
  withStyles(styles),
  withTheme,
  withSortingAndPagination
)(AccountTable);
