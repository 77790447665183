import axios from 'axios';
import * as Sentry from '@sentry/browser';

const registerAxiosInterceptors = (onUnauthenticated, OnUnauthorized, onUnreachable) => {
  const onRequestSuccess = config => {
    config.timeout = 20000;
    config.withCredentials = true;
    config.baseURL = process.env.REACT_APP_API_URL;
    return config;
  };
  const onRequestError = error => {
    console.error(error);
    return Promise.reject(error);
  };
  const onResponseSuccess = response => response;
  const onResponseError = error => {
    const status = error.response ? error.response.status : error.status || 'server unrecheable';
    let errorCode = null;
    try {
      errorCode = error.response.data.code;
      console.error('errorCode :' + errorCode);
    } catch (e) {
      /* ignore error */
    }
    Sentry.captureException(error);
    // If no customize error message code, we use global method
    if (!errorCode) {
      switch (status) {
        case 401:
          if (onUnauthenticated) onUnauthenticated();
          break;
        case 403:
          console.warn('Unauthorized access - access denied');
          if (OnUnauthorized) OnUnauthorized();
          break;
        default:
          console.warn(`Unmanaged http code: ${status}`);
          if (onUnreachable) onUnreachable();
      }
    }
    return Promise.reject(error);
  };
  axios.interceptors.request.use(onRequestSuccess, onRequestError);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export { registerAxiosInterceptors };
