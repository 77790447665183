import React from 'react';
import { TablePagination } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

function applyFnToValueIfExists(val, fn) {
  return fn ? fn(val) : val;
}
function sorter(a, b, orderBy, desc, specificSorting) {
  let deepA;
  let deepB;
  if (orderBy.length > 1) {
    deepA = a[orderBy[0]] ? a[orderBy[0]][orderBy[1]] : a[orderBy[0]];
    deepB = b[orderBy[0]] ? b[orderBy[0]][orderBy[1]] : b[orderBy[0]];
  } else {
    deepA = a[orderBy[0]];
    deepB = b[orderBy[0]];
  }
  // apply value transformation if necessary before transformation
  deepA = specificSorting ? applyFnToValueIfExists(deepA, specificSorting[orderBy[0]]) : deepA;
  deepB = specificSorting ? applyFnToValueIfExists(deepB, specificSorting[orderBy[0]]) : deepB;
  if (deepA == null) {
    return 1;
  }
  if (deepB == null) {
    return -1;
  }
  if (deepB < deepA) {
    return desc ? -1 : 1;
  }
  if (deepB > deepA) {
    return desc ? 1 : -1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting({ order, orderBy }, specificSorting) {
  orderBy = orderBy.split('#');
  return (a, b) => sorter(a, b, orderBy, order === 'desc', specificSorting);
}
const labelDisplayedRows = ({ from, to, count }) => `${from}-${to} de ${count}`;
const labelRowsPerPage = 'Lignes par page';

const withSortingAndPagination = WrappedComponent => {
  // eslint-disable-next-line react/display-name
  return observer(
    ({
      data,
      defaultSortingColumn,
      defaultSortingDirection = 'asc',
      specificSorting,
      ...props
    }) => {
      const [{ page, rowsPerPage }, setPagination] = React.useState({ page: 0, rowsPerPage: 10 });
      const [sorting, setSorting] = React.useState({
        order: defaultSortingDirection,
        orderBy: defaultSortingColumn,
      });

      const handleRequestSort = property => evt => {
        const orderBy = property;
        let order = 'desc';

        if (sorting.orderBy === property && sorting.order === 'desc') {
          order = 'asc';
        }
        setSorting({ order, orderBy });
      };

      const handleChangePage = (evt, page) => {
        setPagination({ page, rowsPerPage });
      };
      const handleChangeRowsPerPage = evt => {
        setPagination({ page, rowsPerPage: evt.target.value });
      };
      const sortedElementsAll = stableSort(data, getSorting(sorting, specificSorting));
      const sortedAndPaginatedElements = stableSort(
        data,
        getSorting(sorting, specificSorting)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
      return (
        <React.Fragment>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
            labelRowsPerPage={labelRowsPerPage}
          />
          <WrappedComponent
            sorting={sorting}
            emptyRows={emptyRows}
            allElements={sortedElementsAll}
            sortedElements={sortedAndPaginatedElements}
            onRequestSort={handleRequestSort}
            {...props}
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
            labelRowsPerPage={labelRowsPerPage}
          />
        </React.Fragment>
      );
    }
  );
};

export default withSortingAndPagination;
