import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
} from 'utils/forms';
import { isRequired } from 'utils/forms/validators';

export default function eventDescriptionormBuilder({
  initialValues = null,
  mode = FORM_MODE_CREATE,
}) {
  const fields = {
    description: new Field({
      initValue: (initialValues && initialValues.description) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
