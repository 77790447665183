import { Grid, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { Email, Person, Phone, PersonPin } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { getInputFieldProps } from 'utils/forms';
import Selects from 'components/form/common/Selects';
import withEditableForm from '../common/withEditableForm';
import { t } from 'utils/i18n';
import { formatPhoneNumber, capitalizeFirstLetter } from 'utils/format';

const Signalant = observer(({ form }) => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            id="firstname"
            name="firstname"
            label={t('declaration.signalant.firstname.label')}
            fullWidth
            autoComplete="firstname"
            {...getInputFieldProps(form.firstname, {}, { formatter: capitalizeFirstLetter })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="lastname"
            name="lastname"
            label={t('declaration.signalant.lastname.label')}
            fullWidth
            autoComplete="lastname"
            {...getInputFieldProps(form.lastname, {}, { formatter: capitalizeFirstLetter })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label={t('declaration.signalant.address.label')}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Navigation />
                </InputAdornment>
              ),
            }}
            {...getInputFieldProps(form.address)}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Selects
            required
            fullWidth
            label={t('declaration.signalant.organisation.label')}
            // startAdornment={<Business style={{ marginRight: 10 }} />}
            {...getInputFieldProps(form.role)}
            inputProps={{
              name: 'role',
              id: 'role-select',
              placeholder: t('declaration.signalant.organisation.label'),
            }}>
            <MenuItem value={'ETS'}>Institution sanitaire (publique, privée, mixte…)</MenuItem>
            <MenuItem value={'DPC'}>DPC</MenuItem>
            <MenuItem value={'CAN'}>CAN / Ambulance</MenuItem>
            <MenuItem value={'PRS'}>
              Professionnel de la santé (hors institution sanitaire)
            </MenuItem>
            <MenuItem value={'AUT'}>
              Autorités (Délégué départemental, mairie, CASEC, ASEC…)
            </MenuItem>
            <MenuItem value={'ONG'}>ONG</MenuItem>
            <MenuItem value={'PNH'}>PNH</MenuItem>
            <MenuItem value={'OGI'}>Organisations Internationales</MenuItem>
            <MenuItem value={'OTH'}>Autre</MenuItem>
          </Selects>
        </Grid>
        <Grid item xs={12}>
          {/* <Collapse in={form.role.value === 'OTH'}> */}
          <TextField
            required
            id="role-other"
            name="role-other"
            label={t('declaration.signalant.function.label')}
            fullWidth
            {...getInputFieldProps(form.roleOther)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonPin />
                </InputAdornment>
              ),
            }}
          />
          {/* </Collapse> */}
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="phone"
            name="phone"
            type="tel"
            label={t('declaration.signalant.phone.label')}
            autoComplete="tel-national"
            fullWidth
            {...getInputFieldProps(form.phone, {}, { formatter: formatPhoneNumber })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            label="Email"
            autoComplete="email"
            fullWidth
            {...getInputFieldProps(form.email)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default withEditableForm(Signalant);
