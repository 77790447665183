import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
} from 'utils/forms';
import { isRequired, isEmail } from 'utils/forms/validators';

export default function signalantFormBuilder({ initialValues = null, mode = FORM_MODE_CREATE }) {
  const fields = {
    firstname: new Field({
      initValue: (initialValues && initialValues.firstname) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    lastname: new Field({
      initValue: (initialValues && initialValues.lastname) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    // address: new Field({
    //   initValue: (initialValues && initialValues.address) || '',
    //   disabled: mode === FORM_MODE_CONSULT,
    //   validate: [isRequired],
    //   validateOn: [FORM_VALID_ON_BLUR],
    // }),
    role: new Field({
      initValue: (initialValues && initialValues.role) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Role',
    }),
    roleOther: new Field({
      initValue: (initialValues && initialValues.roleOther) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [
        (value, label) => {
          return fields.role.value === 'OTH'
            ? isRequired(value, label)
            : () => ({
                valid: true,
              });
        },
      ],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Role (autre)',
    }),
    phone: new Field({
      initValue: (initialValues && initialValues.phone) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      label: 'Téléphone',
    }),
    email: new Field({
      initValue: (initialValues && initialValues.email) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isEmail],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Email',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
