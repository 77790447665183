import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
} from 'utils/forms';
import { isRequired } from 'utils/forms/validators';
import { DateTime } from 'luxon';

export default function eventDateFormBuilder({ initialValues, mode = FORM_MODE_CREATE }) {
  // const now = new Date();
  const fields = {
    date: new Field({
      initValue: (initialValues && DateTime.fromISO(initialValues.date).toJSDate()) || null,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Date',
    }),
    time: new Field({
      initValue: (initialValues && DateTime.fromISO(initialValues.time).toJSDate()) || null,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Heure',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
