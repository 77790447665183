import { observe } from 'mobx';

export const syncHistoryWithStore = (history, store) => {
  // Initialise store
  store.history = history;

  // Handle update from history object
  function handleLocationChange(location) {
    store._updateLocation(location);
  }

  const unsubscribeFromHistory = history.listen(handleLocationChange);
  handleLocationChange(history.location);

  function subscribe(listener) {
    function onStoreChange() {
      const rawLocation = { ...store.location };
      console.debug('OnStoreChange: ' + rawLocation);
      listener(rawLocation, history.action);
    }

    // Listen for changes to location state in store
    const unsubscribeFromStore = observe(store, 'location', onStoreChange);

    listener(store.location, history.action);

    return unsubscribeFromStore;
  }

  history.subscribe = subscribe;
  history.unsubscribe = unsubscribeFromHistory;

  return history;
};
