import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField, IconButton, withStyles, Fab, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import compose from 'utils/compose';
import shortid from 'shortid';
import { isRequired } from 'utils/forms/validators';
import { FORM_MODE_CONSULT, isEditionOrCreationMode } from 'utils/forms';
import { compareMaps } from 'utils';

const initState = values => {
  let valueMap;
  if (values && values.length) {
    const mappedValues = values.map(action => [shortid.generate(), action]);
    valueMap = Array.from(mappedValues);
  } else {
    valueMap = [[shortid.generate(), '']];
  }
  return valueMap;
};

const Actions = ({ classes, mode, initialValues, onSubmit }) => {
  const [actions, setActions] = useState(new Map(initState(initialValues)));
  const [isSaveDisabled, disableSave] = useState(false);
  const [errors, setErrors] = React.useState(new Map());

  // Set actions again on component level if property "initialValues" changes
  useEffect(() => {
    setActions(new Map(initState(initialValues)));
  }, [initialValues]);

  // Check if values are different from initial state.
  useEffect(() => {
    const areEqual = compareMaps(new Map(initState(initialValues)), actions);
    disableSave(areEqual);
  }, [initialValues, actions]);

  const validate = (value, index) => {
    const { valid, message } = isRequired(value);
    const updatedErrors = new Map(errors.set(index, valid ? false : message));
    setErrors(updatedErrors);
    return valid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!isEditionOrCreationMode(mode)) return;
    // Validate first actions (required) before submitting
    if (validate(actions.values().next().value, 0)) {
      onSubmit(Array.from(actions.values()).filter(v => v && v.length > 0));
    }
  };

  const handleAddAction = e => {
    actions.set(shortid.generate(), '');
    setActions(new Map(actions));
  };

  const handleDeleteAction = index => e => {
    actions.delete(index);
    setActions(new Map(actions));
  };

  const handleChange = index => e => {
    actions.set(index, e.target.value);
    setActions(new Map(actions));
  };

  const handleBlur = e => {
    validate(e.target.value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {Array.from(actions.entries()).map(([key, ap], index) => {
          const error = errors.get(index);
          return (
            <div key={`action-${key}`} className={classes.inputContainer}>
              {index === 0 && (
                <TextField
                  required
                  id={`action${key}`}
                  name={`action${key}`}
                  fullWidth
                  placeholder="Description de l'action ou du contact"
                  label={`Action n°${index + 1}`}
                  margin="normal"
                  value={ap}
                  onChange={handleChange(key)}
                  onBlur={handleBlur}
                  error={!!error}
                  helperText={!!error && error}
                  disabled={mode === FORM_MODE_CONSULT}
                />
              )}
              {index > 0 && (
                <React.Fragment>
                  <TextField
                    id={`action${key}`}
                    name={`action${key}`}
                    fullWidth
                    placeholder="Description de l'action ou du contact"
                    label={`Action n°${index + 1}`}
                    margin="normal"
                    value={ap}
                    onChange={handleChange(key)}
                    // error={!!error}
                    // helperText={!!error && error}
                    disabled={mode === FORM_MODE_CONSULT}
                  />
                  {isEditionOrCreationMode(mode) && (
                    <Tooltip title="Supprimer" aria-label="Supprimer action">
                      <IconButton onClick={handleDeleteAction(key)}>
                        <DeleteIcon className={classes.button} />
                      </IconButton>
                    </Tooltip>
                  )}
                </React.Fragment>
              )}
            </div>
          );
        })}
      </Grid>
      {isEditionOrCreationMode(mode) && (
        <React.Fragment>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Tooltip title="Ajouter une action" aria-label="Ajouter">
                  <Fab color="primary" onClick={handleAddAction} className={classes.fab}>
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.buttonSubmit}
              disabled={isSaveDisabled}>
              Enregistrer
            </Button>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonSubmit: {
    marginBottom: theme.spacing(1),
  },
  informationText: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  inputContainer: {
    flex: 1,
  },
  iconLeft: {
    marginRight: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(1),
  },
});
export default compose(
  React.memo,
  withStyles(styles)
)(Actions);
