import { Button, Collapse, Grid, Link, withWidth } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import axios from 'axios';
import Alerte from 'components/Alerte';
import Actions from 'components/form/Actions';
import { CommentForm, commentFormBuilder, CommentList } from 'components/form/Comments';
import Deceased, { deceasedFormBuilder } from 'components/form/Deceased';
import EventDate from 'components/form/EventDate';
import eventDateFormBuilder from 'components/form/EventDate/eventDateFormDataBuilder';
import EventDescription, { eventDescriptionFormBuilder } from 'components/form/EventDescription';
import EventLocation from 'components/form/EventLocation';
import eventLocationFormBuilder from 'components/form/EventLocation/eventLocationFormDataBuilder';
import EventType from 'components/form/EventType';
import eventTypeFormBuilder from 'components/form/EventType/eventTypeFormDataBuilder';
import { GeographicZone, geographicZoneFormBuilder } from 'components/form/GeographicZone';
import Injuried, { injuriedFormBuilder } from 'components/form/Injuried';
import Permanencier from 'components/form/Permanencier';
import permanencierFormBuilder from 'components/form/Permanencier/permanencierFormDataBuilder';
import Signalant from 'components/form/Signalant';
import signalantFormBuilder from 'components/form/Signalant/signalantFormDataBuilder';
import {
  ACTIONS,
  ALERT_NUMBER,
  DECEASED,
  DEPARTMENT,
  EVENT_DATE,
  EVENT_DESCRIPTION,
  EVENT_LOCATION,
  EVENT_TYPE,
  INJURIED,
  PERMANENCIER,
  SIGNALANT,
} from 'constants/index';
import { action, decorate, observable, transaction, runInAction, autorun } from 'mobx';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import React from 'react';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { postComment, validateFiche } from 'services/DeclarationService';
import compose from 'utils/compose';
import { dateValidOrConvertFromDateTime } from 'utils/DateUtils';
import {
  FORM_MODE_CREATE,
  FORM_MODE_EDIT,
  isConsultationMode,
  isCreationMode,
  isEditionOrCreationMode,
} from 'utils/forms';
import { t } from 'utils/i18n';
import scrollToElement from 'scroll-to-element';

class DeclarationPage extends React.Component {
  constructor(props) {
    super(props);
    const { declarationStore } = this.props;

    autorun(() => {
      declarationStore.changeMode(FORM_MODE_CREATE);
      declarationStore.initStore();
      this.permanencierForm = permanencierFormBuilder({});
      this.geographicZoneForm = geographicZoneFormBuilder({});
      this.signalantForm = signalantFormBuilder({});
      this.eventDateForm = eventDateFormBuilder({});
      this.eventDateForm.setTouched();
      this.eventLocationForm = eventLocationFormBuilder({});
      this.eventTypeForm = eventTypeFormBuilder({});
      this.injuriedForm = injuriedFormBuilder({});
      this.deceasedForm = deceasedFormBuilder({});
      this.eventDescriptionForm = eventDescriptionFormBuilder({});
      this.commentForm = commentFormBuilder({});
    });
  }

  permanencier;
  fetchDeclaration;
  permanencierForm;
  geographicZoneForm;
  signalantForm;
  eventDateForm;
  eventLocationForm;
  eventTypeForm;
  injuriedForm;
  deceasedForm;
  eventDescriptionForm;
  commentForm;

  assignFetchDeclaration = action(declarationId => {
    this.fetchDeclaration = observable(fromPromise(axios.get(`/declarations/${declarationId}`)));
    return this.fetchDeclaration;
  });

  handleSubmitComment = async ({ comment }) => {
    const { declarationStore, notificationStore } = this.props;
    const { declaration, mode } = declarationStore;
    return postComment(declaration.id, {
      permanencier: this.permanencier,
      message: comment,
      declaration: declaration,
    })
      .then(
        action(({ data }) => {
          declarationStore.updateComments(data);
          notificationStore.notifySuccess('Commentaire ajouté');
          this.commentForm = commentFormBuilder({
            mode,
          });
        })
      )
      .then(
        action(() => {
          if (declarationStore.isCommentMode) {
            declarationStore.toggleCommentMode(false);
            this.permanencier = null;
          }
        })
      );
  };

  handleSubmitEventDate = async ({ date, time }) => {
    const { declarationStore, notificationStore } = this.props;
    const { declaration } = declarationStore;
    return axios
      .post(`/declarations/${declaration.id}/save`, {
        ...declaration,
        permanencier: this.permanencier,
        eventDate: {
          date: dateValidOrConvertFromDateTime(date),
          time: dateValidOrConvertFromDateTime(date),
        },
      })
      .then(res => {
        declarationStore.updateData({ ...res.data, permanencier: this.permanencier });
        if (declarationStore.isFilled(EVENT_DATE)) {
          notificationStore.notifySuccess(`"${t(`sections.${EVENT_DATE}`)}" modifiée avec succès`);
        } else {
          notificationStore.notifySuccess(
            `"${t(`sections.${EVENT_DATE}`)}" enregistrée avec succès`
          );
        }
        declarationStore.sectionFilled(EVENT_DATE);
      });
  };

  handleSubmitPermanencier = async permanencier => {
    const { declarationStore, notificationStore } = this.props;
    return axios
      .post(`/permanencier/validate`, permanencier)
      .then(
        action(res => {
          // Not updating store with data, we are only validating the permanencier
          declarationStore.sectionFilled(PERMANENCIER);
          this.permanencier = res.data; // Volatile value, should be reset each time we access the page
        })
      )
      .then(() => notificationStore.notifySuccess('Permanencier enregistré'))
      .then(() => {
        // Scroll directly to comment section if "comment mode" activated
        if (declarationStore.isCommentMode) scrollToElement('#comments-list', { offset: -20 });
      })
      .catch(err => {
        notificationStore.notifyError("Echec de l'enregistrement");
        console.error(err);
      });
  };

  handleSubmitGeographicZone = async ({ department }) => {
    const { declarationStore, notificationStore } = this.props;
    const { declaration, mode } = declarationStore;
    const action =
      isCreationMode(mode) && (!declaration || !declaration.alertNumber)
        ? 'create'
        : `${declaration.id}/department`;
    return axios
      .post(`/declarations/${action}`, {
        department,
        permanencier: this.permanencier,
      })
      .then(res => {
        transaction(() => {
          declarationStore.updateData(res.data);
          declarationStore.sectionFilled(DEPARTMENT);
          declarationStore.sectionFilled(ALERT_NUMBER);
        });
      })
      .then(() => notificationStore.notifySuccess('Département enregistrée'))
      .catch(err => {
        console.error(err);
        notificationStore.notifyError("Echec de l'enregistrement");
      });
  };

  handleSubmitSection = (key, sectionName, isField = false) => async sectionData => {
    const { declarationStore, notificationStore } = this.props;
    const { declaration } = declarationStore;
    return axios
      .post(`/declarations/${declaration.id}/save`, {
        ...declaration,
        permanencier: this.permanencier,
        [key]: isField ? sectionData[key] : sectionData,
      })
      .then(res => {
        declarationStore.updateData({ ...res.data, permanencier: this.permanencier });
        if (declarationStore.isFilled(sectionName)) {
          notificationStore.notifySuccess(
            `"${t(`sections.${sectionName}`, sectionName)}" modifié(e) avec succès`
          );
        } else {
          notificationStore.notifySuccess(
            `"${t(`sections.${sectionName}`, sectionName)}" enregistré(e) avec succès`
          );
        }
        declarationStore.sectionFilled(sectionName);
      })
      .then(() => {
        // Scroll to page bottom after filling last section
        if (key === 'actions') {
          scrollToElement('#validation-button');
        }
      });
  };

  sectionShouldDisplay = (previousSection, currentSection) => {
    const { declarationStore } = this.props;
    return declarationStore.sectionShouldDisplay(previousSection, currentSection);
  };

  validateWholeDeclaration = () => {
    const { authenticationStore, declarationStore, notificationStore, routingStore } = this.props;
    const { declaration } = declarationStore;
    if (authenticationStore.isNational) {
      validateFiche(declaration.id, this.permanencier).then(() => {
        notificationStore.notifySuccess('Fiche enregistrée et validée avec succès');
        declarationStore.resetStore();
        routingStore.push(`/search`);
      });
    } else {
      notificationStore.showDialog({
        title: 'Confirmation',
        message: t('declaration.messages.validation'),
        onConfirm: () => {
          validateFiche(declaration.id, this.permanencier).then(() => {
            notificationStore.notifySuccess('Fiche enregistrée et validée avec succès');
            declarationStore.resetStore();
            routingStore.push(`/search`);
          });
        }, // Save and update status
        onCancelLabel: 'Modifier',
      });
    }
  };

  componentWillUnmount() {
    runInAction(() => {
      this.props.declarationStore.resetStore();
      this.permanencier = null;
    });
  }

  render() {
    const { classes, declarationStore } = this.props;
    const { mode, declaration } = declarationStore;
    return (
      <React.Fragment>
        {/* <Paper className={classes.breadcrumbsPaper}> */}
        <Breadcrumbs separator=">" aria-label="Breadcrumb">
          <Link component={RouterLink} color="inherit" to="/" replace={true}>
            Accueil
          </Link>
          <Link component={RouterLink} color="inherit" to="/search" replace={true}>
            Liste des signalements
          </Link>

          <Typography color="textPrimary">Signalement</Typography>
        </Breadcrumbs>
        {/* </Paper> */}
        <Paper className={classes.paper}>
          <Typography id="permanencier-title" variant="h6" gutterBottom align="left">
            1. Permanencier
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom align="left">
            Personne saisissant les informations
          </Typography>
          <Permanencier
            isFilled={declarationStore.isFilled(PERMANENCIER)}
            onSubmit={this.handleSubmitPermanencier}
            mode={declarationStore.isCommentMode ? FORM_MODE_EDIT : mode}
            form={this.permanencierForm}
          />
        </Paper>
        <Collapse in={this.sectionShouldDisplay(PERMANENCIER, DEPARTMENT)}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="left">
              2. Département
            </Typography>
            <GeographicZone
              form={this.geographicZoneForm}
              onSubmit={this.handleSubmitGeographicZone}
              mode={mode}
            />
          </Paper>
        </Collapse>
        <Collapse in={this.sectionShouldDisplay(DEPARTMENT, SIGNALANT)}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="left">
              3. Signalant
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom align="left">
              Personne rapportant les informations
            </Typography>
            <Signalant
              isFilled={declarationStore.isFilled(SIGNALANT)}
              onSubmit={this.handleSubmitSection('signalant', SIGNALANT)}
              form={this.signalantForm}
              mode={mode}
            />
          </Paper>
        </Collapse>
        <Collapse in={this.sectionShouldDisplay(SIGNALANT, ALERT_NUMBER)}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="left">
              4. Alerte & Signalement
            </Typography>
            <Alerte
              alertNumber={declaration.alertNumber}
              signalmentDate={declaration.signalmentDate}
            />
          </Paper>
        </Collapse>
        <Collapse in={this.sectionShouldDisplay(SIGNALANT, EVENT_DATE)}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="left">
              5. Événement
            </Typography>
            <EventDate
              isFilled={declarationStore.isFilled(EVENT_DATE)}
              onSubmit={this.handleSubmitEventDate}
              form={this.eventDateForm}
              mode={mode}
            />
          </Paper>
        </Collapse>
        <Collapse in={this.sectionShouldDisplay(EVENT_DATE, EVENT_TYPE)}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="left">
              6. Type d'évènement
            </Typography>
            <EventType
              isFilled={declarationStore.isFilled(EVENT_TYPE)}
              onSubmit={this.handleSubmitSection('eventType', EVENT_TYPE)}
              form={this.eventTypeForm}
              mode={mode}
            />
          </Paper>
        </Collapse>
        <Collapse in={this.sectionShouldDisplay(EVENT_TYPE, EVENT_LOCATION)}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="left">
              7. Lieu de l'événement
            </Typography>
            <EventLocation
              isFilled={declarationStore.isFilled(EVENT_LOCATION)}
              onSubmit={this.handleSubmitSection('eventLocation', EVENT_LOCATION)}
              form={this.eventLocationForm}
              mode={mode}
            />
          </Paper>
        </Collapse>
        <Collapse in={this.sectionShouldDisplay(EVENT_LOCATION, INJURIED)}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="left">
              8. Nombre de blessés ou malades estimés
            </Typography>
            <Injuried
              isFilled={declarationStore.isFilled(INJURIED)}
              onSubmit={this.handleSubmitSection('injuried', INJURIED, true)}
              form={this.injuriedForm}
              mode={mode}
            />
          </Paper>
        </Collapse>
        <Collapse in={this.sectionShouldDisplay(INJURIED, DECEASED)}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="left">
              9. Nombre de décès estimés
            </Typography>
            <Deceased
              isFilled={declarationStore.isFilled(DECEASED)}
              onSubmit={this.handleSubmitSection('deceased', DECEASED, true)}
              form={this.deceasedForm}
              mode={mode}
            />
          </Paper>
        </Collapse>
        <Collapse in={this.sectionShouldDisplay(DECEASED, EVENT_DESCRIPTION)}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="left">
              10. Description de l'évènement
            </Typography>
            <EventDescription
              isFilled={declarationStore.isFilled(EVENT_DESCRIPTION)}
              onSubmit={this.handleSubmitSection('eventDescription', EVENT_DESCRIPTION)}
              form={this.eventDescriptionForm}
              mode={mode}
            />
          </Paper>
        </Collapse>
        <Collapse in={this.sectionShouldDisplay(EVENT_DESCRIPTION, ACTIONS)}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom align="left">
              11. Actions déjà entreprises / Personnes déjà alertées
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom align="left">
              Au moins une action ou personne contactée doit être notifiée
            </Typography>
            <Actions
              isFilled={declarationStore.isFilled(ACTIONS)}
              onSubmit={this.handleSubmitSection('actions', ACTIONS)}
              initialValues={declaration.actions}
              mode={mode}
            />
          </Paper>
        </Collapse>
        <Collapse
          in={
            declarationStore.isCommentMode ||
            (isEditionOrCreationMode(mode) && declarationStore.isFilled(PERMANENCIER)) ||
            (declaration.comments && declaration.comments.length > 0)
          }>
          <Paper className={classes.paper}>
            <Typography id="comments-title" variant="h6" gutterBottom align="left">
              Commentaires
            </Typography>
            <Collapse
              in={
                (declarationStore.isCommentMode && this.permanencier) ||
                (isEditionOrCreationMode(mode) && declarationStore.isFilled(PERMANENCIER))
              }>
              <CommentForm
                id="comments-form"
                onSubmit={this.handleSubmitComment}
                form={this.commentForm}
                mode={declarationStore.isCommentMode ? FORM_MODE_EDIT : mode}
                comments={declaration && declaration.comments}
              />
            </Collapse>
            <CommentList comments={declaration.comments} />
          </Paper>
        </Collapse>
        <Collapse
          in={
            !isConsultationMode(mode) &&
            declarationStore.sectionsFilledForValidation &&
            declaration.status === 'DRAFT'
          }>
          <Grid container direcion="row" justify="flex-end" spacing={3}>
            <Grid item xs={12}>
              <Button
                id="validation-button"
                variant="contained"
                color="primary"
                size="large"
                onClick={this.validateWholeDeclaration}>
                Valider la fiche
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  layout: {},
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  item: {
    margin: '10px',
  },
  floatingButtonIcon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  floatingButton: {
    margin: theme.spacing(1),
    minWidth: '190px !important',
    [theme.breakpoints.down('sm')]: {
      minWidth: '0 !important',
    },
  },
  floatingButtonWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  floatingButtonContainer: {
    margin: theme.spacing(1),
    position: 'fixed',
    top: 'calc(90vh - 200px)',
    right: '1vw',
  },
  breadcrumbsPaper: {
    width: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
  },
});

export default compose(
  inject('authenticationStore', 'declarationStore', 'notificationStore', 'routingStore'),
  withRouter,
  withStyles(styles),
  withWidth(),
  observer
)(
  decorate(DeclarationPage, {
    fetchDeclaration: observable,
    permanencierForm: observable,
    signalantForm: observable,
    eventDateForm: observable,
    eventLocationForm: observable,
    eventTypeForm: observable,
    injuriedForm: observable,
    deceasedForm: observable,
    eventDescriptionForm: observable,
    commentForm: observable,
    permanencier: observable,
  })
);
