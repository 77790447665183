export function isValid(fields) {
  return fields.every(field => field.isValid);
}

export function setTouched(values) {
  values.forEach(field => field.markAsTouch());
}

function mergeHandler(handler, optHandler) {
  if (!optHandler) {
    return handler;
  } else {
    return (...args) => {
      optHandler(...args);
      handler(...args);
    };
  }
}

function withFormatter(value, formatter) {
  if (!formatter) {
    return value;
  } else {
    return formatter(value);
  }
}

export function getInputFieldProps(
  field,
  { onChange, onBlur, ...others } = {},
  { formatter } = {}
) {
  return (
    field && {
      value: withFormatter(field.value, formatter),
      onChange: mergeHandler(field.onChange, onChange),
      error: !!field.errorMessage,
      onBlur: mergeHandler(field.onBlur, onBlur),
      disabled: field.disabled,
      helperText: field.errorMessage,
      // errorMessage: field.errorMessage,
      ...others,
    }
  );
}

export function getFileUploadFieldProps(field, { onChange, onBlur, ...others } = {}) {
  return {
    value: field.value,
    onChange: mergeHandler(field.onChange, onChange),
    error: field.errorMessage,
    onBlur: mergeHandler(field.markAsTouch, onBlur),
    disabled: field.disabled,
    onProgress: field.setProgress,
    ...others,
  };
}

export function getSwitchProps(field) {
  return {
    checked: field.value,
    onChange: field.onChange,
    onBlur: field.markAsTouch,
    disabled: field.disabled,
  };
}

export const FORM_MODE_CONSULT = 'consult';
export const FORM_MODE_EDIT = 'edit';
export const FORM_MODE_CREATE = 'create';

export const FORM_VALID_ON_BLUR = 'onBlur';
export const FORM_VALID_ON_CHANGE = 'onChange';
export const FORM_VALID_ON_SUBMIT = 'onSubmit';

/**
 * Validate that mode is either EDIT or CREATE
 */
export function isCreationMode(mode) {
  return mode === FORM_MODE_CREATE;
}
export function isEditionMode(mode) {
  return mode === FORM_MODE_EDIT;
}
export function isConsultationMode(mode) {
  return mode === FORM_MODE_CONSULT;
}
export function isEditionOrConsultationMode(mode) {
  return mode === FORM_MODE_EDIT || mode === FORM_MODE_CONSULT;
}

export function isEditionOrCreationMode(mode) {
  return mode === FORM_MODE_EDIT || mode === FORM_MODE_CREATE;
}

/**
 * Returns mode if valid or returns CONSULTATION mode.
 */
export function validateMode(mode) {
  if (mode === FORM_MODE_CREATE || mode === FORM_MODE_EDIT || mode === FORM_MODE_CONSULT) {
    return mode;
  } else {
    return FORM_MODE_CONSULT;
  }
}

export { default as Field } from './Field';
export { default as FieldArray } from './FieldArray';
export * from './FieldsAggregator';
