import isEmailValidator from 'validator/lib/isEmail';
import isHexColorValidator from 'validator/lib/isHexColor';
import isValidDate from 'date-fns/isValid';

export const isRequired = (newValue, label, message) => {
  let isValid = !!newValue;
  if (isValid && newValue.push) {
    // If is array then length must be > 0
    isValid = !!newValue.length;
  }
  return {
    valid: isValid,
    message: !isValid ? (message ? message : `${label || 'Champ'} obligatoire`) : '',
  };
};

export const maxLength = (maxLength, property) => (newValue, label) => {
  let value = newValue;
  if (newValue && property) {
    value = newValue[property];
  }

  let isValid = !value || value.length <= maxLength;
  return {
    valid: isValid,
    message: !isValid ? `${label} doit faire ${maxLength} caractères maximum` : '',
  };
};

export const isEmail = (newValue, label) => {
  const isValid = !newValue || isEmailValidator(newValue);
  return {
    valid: isValid,
    message: !isValid ? `${label} non valide` : '',
  };
};

export function isHexColor(newValue, label) {
  const isValid = !newValue || isHexColorValidator(newValue);
  return {
    valid: isValid,
    message: !isValid ? `${label} doit être une couleur hexa valide` : '',
  };
}

export function isDate(newValue, label) {
  const isValid = !newValue || isValidDate(newValue);
  return {
    valid: isValid,
    message: !isValid ? `${label} non valide` : '',
  };
}

export function isAlphanumeric(newValue, label) {
  const isValid = !newValue || !!newValue.match(/^[0-9A-Z-]+$/i);
  return {
    valid: isValid,
    message: !isValid ? `${label || 'Le champ'} doit être alphanumerique` : '',
  };
}

export const forbiddenChar = (char, label, message) => newValue => {
  let value = newValue;
  let isValid = !value || !value.includes(char);
  return {
    valid: isValid,
    message: !isValid
      ? message || `${label || 'Le champ'} ne doit pas contenir le caractère ${char}`
      : '',
  };
};

export const forbiddenChars = (chars = [], label, message) => value => {
  let isValid =
    !value ||
    !chars.filter(char => {
      return value.includes(char);
    }).length > 0;
  return {
    valid: isValid,
    message: !isValid
      ? message || `${label || 'Le champ'} ne doit pas contenir les caractères ${chars.join(' ')}`
      : '',
  };
};
