import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
} from 'utils/forms';
import { isRequired, isEmail } from 'utils/forms/validators';

export default function accountInfoFormBuilder({ initialValues, mode = FORM_MODE_CREATE }) {
  const fields = {
    firstname: new Field({
      initValue: (initialValues && initialValues.firstname) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Prénom',
    }),
    lastname: new Field({
      initValue: (initialValues && initialValues.lastname) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Nom de famille',
    }),
    phone: new Field({
      initValue: (initialValues && initialValues.phone) || '',
      disabled: mode === FORM_MODE_CONSULT,
      label: 'Téléphone',
    }),
    email: new Field({
      initValue: (initialValues && initialValues.email) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isEmail],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Email',
    }),
    role: new Field({
      initValue: (initialValues && initialValues.role) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Role',
    }),
    department: new Field({
      initValue: (initialValues && initialValues.department) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [
        (value, label) => {
          return fields.role.value === 'DEPARTMENTAL'
            ? isRequired(value, label)
            : () => ({
                valid: true,
              });
        },
      ],
      validateOn: [FORM_VALID_ON_BLUR],
      label: 'Département',
    }),
    username: new Field({
      initValue: (initialValues && initialValues.username) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    password: new Field({
      initValue: (initialValues && initialValues.password) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
