import React from 'react';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { withStyles } from '@material-ui/styles';
import compose from 'utils';
import { formatDate } from 'utils/DateUtils';
import { t } from 'utils/i18n';

const HistoricDialog = ({ classes, onClose, open, modifications, width }) => {
  const [expanded, setExpanded] = React.useState(null);
  const handleClickExpand = id => e => {
    setExpanded(id);
  };

  return (
    <Dialog
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      fullScreen={width === 'xs' ? true : false}
      open={open}
      onClose={onClose}
      aria-labelledby="history-dialog-title"
      PaperProps={{
        className: classes.dialog,
      }}>
      <DialogTitle id="history-dialog-title">Historique de la fiche</DialogTitle>
      {(!modifications || modifications.length === 0) && (
        <DialogContent>Aucune modification</DialogContent>
      )}
      {modifications && modifications.length > 0 && (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Personne</TableCell>
              <TableCell>Action</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {modifications &&
              modifications.map(record => (
                <React.Fragment>
                  <TableRow key={record.id}>
                    <TableCell className={classes.historicCell}>
                      {formatDate(record.modificationDate)}
                    </TableCell>
                    <TableCell className={classes.historicCell}>
                      {record.permanencier.firstname} {record.permanencier.lastname}
                    </TableCell>
                    <TableCell className={classes.historicCell}>
                      {t(`declaration.modifications.${record.modificationType}`)}
                    </TableCell>
                    <TableCell className={classes.historicCell}>
                      {expanded !== record.id && (
                        <ExpandMoreIcon
                          className={classes.actionIcon}
                          onClick={handleClickExpand(record.id)}
                        />
                      )}
                      {expanded === record.id && (
                        <ExpandLessIcon
                          className={classes.actionIcon}
                          onClick={handleClickExpand(null)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.expandableRow}>
                    <TableCell className={classes.expandableCell} size="small" colSpan={4}>
                      <Collapse
                        id={`collapse-row-${record.id}`}
                        in={expanded === record.id}
                        timeout="auto"
                        unmountOnExit>
                        <div className={classes.inline}>
                          <ContactPhoneIcon className={classes.expandableIcon} />
                          {record.permanencier && (
                            <Typography variant="subtitle2">{record.permanencier.phone}</Typography>
                          )}
                        </div>
                        <div className={classes.inline}>
                          <ContactMailIcon className={classes.expandableIcon} />
                          {record.permanencier && (
                            <Typography variant="subtitle2">{record.permanencier.email}</Typography>
                          )}
                        </div>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      )}
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = theme => ({
  dialog: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 550,
      minHeight: 400,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 800,
    },
    maxHeight: 600,
    overflowY: 'sroll',
  },
  expandableRow: {
    height: 0,
  },
  expandableCell: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
  inline: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  actionIcon: {
    cursor: 'pointer',
  },
  expandableIcon: {
    marginRight: theme.spacing(1),
  },
  historicCell: {
    borderBottom: 'none',
  },
});

export default compose(
  React.memo,
  withStyles(styles)
)(HistoricDialog);
