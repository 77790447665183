import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';
import compose from 'utils/compose';
import SnackbarContentWrapper from './Notifications/SnackbarContentWrapper';

// eslint-disable-next-line react/display-name
const Notification = React.memo(({ message, variant }) => {
  const [visible, setVisible] = React.useState(true);
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={visible}
        autoHideDuration={5000}
        onClose={() => setVisible(false)}>
        <SnackbarContentWrapper
          onClose={() => setVisible(false)}
          variant={variant}
          message={message}
        />
      </Snackbar>
    </div>
  );
});

const NotificationManager = ({ notificationStore }) => {
  // const { notificationStore: store } = React.useContext(rootStoreContext);
  const { notifications } = notificationStore;
  return notifications ? (
    notificationStore.notifications.map(({ message, type }, index) => {
      return <Notification message={message} variant={type} key={index} />;
    })
  ) : (
    <div />
  );
};

export default compose(
  inject('notificationStore'),
  observer
)(NotificationManager);
