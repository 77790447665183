import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';
import React from 'react';

const Selects = ({
  id,
  disabled,
  label,
  error,
  fullWidth,
  helperText,
  required,
  shrink,
  ...props
}) => (
  <FormControl fullWidth={fullWidth} error={error} required={required}>
    <InputLabel disabled={disabled} shrink={shrink || !!props.value} htmlFor={id}>
      {label}
    </InputLabel>
    <Select disabled={disabled} {...props}>
      {props.children}
    </Select>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);

export default Selects;
