import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { eventTypes, statuses, departments } from 'constants/index';
import SearchIcon from '@material-ui/icons/Search';
import { t } from 'utils/i18n';
import compose from 'utils/compose';
import { inject } from 'mobx-react';
import SelectCheckboxes from 'components/form/common/SelectCheckboxes';

const FilterBloc = ({ onSearch, authenticationStore }) => {
  const [eventType, setEventType] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [department, setDepartment] = React.useState([]);
  const updaters = {
    eventType: setEventType,
    status: setStatus,
    department: setDepartment,
  };

  const handleSelectChange = key => evt => {
    updaters[key](evt.target.value);
    // if (onFilterChange) onFilterChange(key, evt.target.value);
  };

  const handleSelectAll = key => evt => {
    evt.stopPropagation(); //prevent component calling onChange function
    // eslint-disable-next-line
    switch (key) {
      case 'eventType':
        setEventType(eventTypes);
        break;
      case 'status':
        setStatus(statuses);
        break;
      case 'department':
        setDepartment(departments);
    }
  };

  const handleUnselectAll = key => evt => {
    evt.stopPropagation();
    updaters[key]([]);
  };

  const handleClickSearch = e =>
    onSearch({
      eventType: eventType.join(),
      status: status.join(),
      department: department.join(),
    });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom align="left">
          Signalements
        </Typography>
      </Grid>
      {/* <Grid item xs={12}>
        <TextField
          fullWidth
          label="Recherche"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          className={classes.searchBar}
        />
      </Grid> */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4} md={3}>
            <SelectCheckboxes
              label="Type d'évenement"
              fullWidth
              value={eventType}
              onChange={handleSelectChange('eventType')}
              onSelectAll={handleSelectAll('eventType')}
              onUnselectAll={handleUnselectAll('eventType')}
              options={eventTypes.map(e => {
                return { value: e, label: t(`declaration.eventTypes.${e}`, e) };
              })}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <SelectCheckboxes
              label="Statut"
              fullWidth
              value={status}
              onChange={handleSelectChange('status')}
              onSelectAll={handleSelectAll('status')}
              onUnselectAll={handleUnselectAll('status')}
              options={statuses.map(e => {
                return { value: e, label: t(`declaration.status.${e}`, e) };
              })}
            />
          </Grid>
          {authenticationStore.isNational && (
            <Grid item xs={4} md={3}>
              <SelectCheckboxes
                label="Départements"
                fullWidth
                value={department}
                onChange={handleSelectChange('department')}
                onSelectAll={handleSelectAll('department')}
                onUnselectAll={handleUnselectAll('department')}
                options={departments.map(e => {
                  return { value: e, label: t(`departments.${e}`, e) };
                })}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleClickSearch}>
            <SearchIcon />
            Rechercher
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

// const styles = theme => ({
//   searchBar: {
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//       width: '80%',
//     },
//   },
//   iconButton: {
//     fontSize: theme.typography.pxToRem(32),
//   },
// });

export default compose(
  inject('authenticationStore'),
  React.memo
)(FilterBloc);
