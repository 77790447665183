import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Email, Phone } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { getInputFieldProps } from 'utils/forms';
import withEditableForm from '../common/withEditableForm';
import compose from 'utils/compose';
import { capitalizeFirstLetter, formatPhoneNumber } from 'utils/format';

const styles = theme => ({
  subTitle: {
    fontSize: '14px',
    marginTop: ' 20px',
    fontWeight: 700,
  },
  input: {
    minHeight: '12px',
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
  },
});

const Permanencier = observer(({ classes, form }) => {
  return (
    <React.Fragment>
      <Typography variant="subtitle1" className={classes.subTitle} gutterBottom align="left">
        Identité
      </Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="firstName"
            name="firstName"
            label="Prénom"
            required
            fullWidth
            autoComplete="given-name"
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              className: classes.input,
            }}
            {...getInputFieldProps(form.firstname, {}, { formatter: capitalizeFirstLetter })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="lastName"
            name="lastName"
            label="Nom de famille"
            required
            fullWidth
            autoComplete="family-name"
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              className: classes.input,
            }}
            {...getInputFieldProps(form.lastname, {}, { formatter: capitalizeFirstLetter })}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" className={classes.subTitle} gutterBottom align="left">
        Coordonnées
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="phone"
            name="phone"
            label="Téléphone"
            icon="phone"
            fullWidth
            autoComplete="tel-national"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              disableAnimation: true,
            }}
            {...getInputFieldProps(form.phone, {}, { formatter: formatPhoneNumber })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            {...getInputFieldProps(form.email)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default compose(
  React.memo,
  withEditableForm,
  withStyles(styles)
)(Permanencier);
