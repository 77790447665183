import React from 'react';
import { Grid, Paper, Typography, IconButton } from '@material-ui/core';
import { formatDate } from 'utils/DateUtils';
import { Edit, RemoveRedEye } from '@material-ui/icons';
import { t } from 'utils/i18n';

const ResultListMobile = ({ classes = {}, data, handleClick }) => {
  return data.map(it => (
    <Paper key={it.id} className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Typography variant="subtitle2">N° fiche:</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>{it.alertNumber}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle2">Signalement:</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography noWrap>{formatDate(it.creationDate)}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle2">Nbre blessés:</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>{it.injuried}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle2">Nbre décédés:</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>{it.deceased}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle2">Typologie:</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>
            {it.eventType
              ? t(`declaration.eventType.${it.eventType.type}`, it.eventType.type)
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle2">Status:</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>
            {it.eventType ? t(`declaration.status.${it.status}`, it.status) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <IconButton color="primary" aria-label="Consulter" className={classes.iconButton}>
            <RemoveRedEye fontSize="large" />
          </IconButton>
          <IconButton color="primary" aria-label="Modifier" className={classes.iconButton}>
            <Edit fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  ));
};

export default React.memo(ResultListMobile);
