import React from 'react';
import * as Sentry from '@sentry/browser';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Button, Typography, Divider } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  handleClickReportButton = () => Sentry.showReportDialog({ eventId: this.state.eventId });

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <Grid container spacing={3} justify="center">
          <Grid item xs={12}>
            <Typography variant="h3" color="secondary" align="center">
              Une erreur est survenue.
            </Typography>
            <Typography variant="subtitle1" color="inherit" align="center">
              Veuillez nous excuser pour ce désagrément...
            </Typography>
          </Grid>
          <Grid item>
            <Link variant="subtitle1" color="primary" align="center" component={RouterLink} to="/">
              Retourner à l'accueil
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item>
            <Button color="secondary" variant="contained" onClick={this.handleClickReportButton}>
              Signaler l'erreur
            </Button>
          </Grid>
        </Grid>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
