import { Grid, Input, MenuItem } from '@material-ui/core';
import { departments } from 'constants/index';
import React from 'react';
import compose from 'utils/compose';
import { getInputFieldProps } from 'utils/forms';
import { t } from 'utils/i18n';
import Selects from '../common/Selects';
import withEditableForm from '../common/withEditableForm';

const GeographicZone = ({ form }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Selects
          fullWidth
          placeholder="Nom du Département"
          input={<Input name="dept" />}
          {...getInputFieldProps(form.department)}>
          {departments.map((v, i) => (
            <MenuItem key={`${i}-${v}`} value={v}>
              {t(`departments.${v}`, v)}
            </MenuItem>
          ))}
        </Selects>
      </Grid>
    </Grid>
  );
};

export default compose(
  React.memo,
  withEditableForm
)(GeographicZone);
