import { Field, fieldsAggregatorBuilder, FORM_VALID_ON_BLUR } from 'utils/forms';
import { isRequired } from 'utils/forms/validators';

export default function loginFormFormBuilder({ initialValues }) {
  const fields = {
    username: new Field({
      initValue: (initialValues && initialValues.username) || '',
      // disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    password: new Field({
      initValue: (initialValues && initialValues.password) || '',
      // disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
