import React from 'react';
import compose from 'utils/compose';
import { inject } from 'mobx-react';
import { isConsultationMode } from 'utils/forms';
import { observer } from 'mobx-react-lite';
import AppMenuDrawer from './AppMenuDrawer';
import AppToolbar from './AppToolbar';
import { t, formatMsg } from 'utils/i18n';

const AppToolBarDrawer = ({
  authenticationStore,
  declarationStore,
  notificationStore,
  routingStore,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawer = isOpen => e => {
    setOpen(isOpen);
  };
  const isSelected = path => routingStore.location.pathname === path;

  const goTo = to => e => {
    if (isSelected(to)) {
      return;
    }
    if (
      !isConsultationMode(declarationStore.mode) &&
      Object.keys(declarationStore.declaration).length
    ) {
      notificationStore.showDialog({
        title: 'Confirmation',
        message: formatMsg('notifications.exitDeclaration', {
          status: t(`declaration.status.${declarationStore.status}`),
        }),
        onConfirm: () => {
          declarationStore.resetStore();
          routingStore.push(to);
        },
      });
    } else {
      routingStore.push(to);
    }
  };

  return (
    <div>
      <AppMenuDrawer
        handleDrawer={handleDrawer}
        isAuthenticated={authenticationStore.isAuthenticated}
        isNational={authenticationStore.isNational}
        isCurrentPath={isSelected}
        isMenuOpen={open}
        onClickLink={goTo}
        onDisconnect={authenticationStore.disconnect}
        user={authenticationStore.user}
      />
      <AppToolbar
        isAuthenticated={authenticationStore.isAuthenticated}
        onClickLink={goTo}
        onClickMenu={handleDrawer(true)}
      />
    </div>
  );
};

export default compose(
  React.memo,
  inject('authenticationStore', 'declarationStore', 'notificationStore', 'routingStore'),
  observer
)(AppToolBarDrawer);
