import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem, IconButton, ListItemIcon, Typography } from '@material-ui/core';

const TableRowMenu = ({ menuOptions = [] }) => {
  const [anchorEl, setAnchorEl] = React.useState();
  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuOptionClick = (customHandle = () => null) => e => {
    e.preventDefault();
    e.stopPropagation();
    customHandle(e);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="More"
        // aria-owns={anchorEl ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        // PaperProps={{
        //   style: {
        //     // maxHeight: ITEM_HEIGHT * 4.5,
        //     width: 150,
        //   },
        // }}
      >
        {menuOptions
          .filter(option => option.visible)
          .map(option => (
            <MenuItem
              key={option.key}
              disabled={option.disabled}
              onClick={handleMenuOptionClick(option.onClick)}>
              {option.icon && (
                <ListItemIcon>
                  <option.icon color={option.color} />
                </ListItemIcon>
              )}
              <Typography variant="inherit" color={option.color}>
                {option.name}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default React.memo(TableRowMenu);
