export default {
  declaration: {
    status: {
      DRAFT: 'Brouillon',
      VALIDATED: 'Validé',
      ARCHIVED: 'Archivé',
      CLOSED: 'Clôturé',
    },
    eventTypes: {
      ACC: 'Accident de circulation',
      MAN: 'Manifestation',
      MVF: 'Mouvement de foule',
      TIR: 'Tir',
      FUS: 'Fusillade',
      INC: 'Incendie',
      EXP: 'Explosion',
      MVT: 'Glissement de terrain',
      SEI: 'Séisme',
      TRT: 'Tremblement de terre',
      CYC: 'Cyclone',
      INO: 'Inondation',
      SEC: 'Sécheresse',
      EFB: 'Effondrement de bâtiment',
      TSU: 'Tsunami',
      ITX: 'Intoxication',
      CHO: 'Choléra',
      DAA: 'Diarrhée aigüe aqueuse',
      DAS: 'Diarrhée aigüe sanglante',
      ROU: 'Rougeole/Rubéole',
      PAL: 'Malaria / Paludisme',
      MEN: 'Méningite',
      DEN: 'Dengue',
      ZIK: 'Zika',
      CIK: 'Chikungunya',
      DIP: 'Diphtérie',
      OTH: 'Autre',
    },
    injuried: {
      I: 'Inconnu',
      N: '0',
      A: '< 10',
      B: '10 - 50',
      C: '50 - 100',
      D: '> 100',
    },
    deceased: {
      I: 'Inconnu',
      N: '0',
      A: '< 10',
      B: '10 - 50',
      C: '50 - 100',
      D: '> 100',
    },
    messages: {
      validation:
        'Attention, merci de bien vérifier les informations fournies qui seront visibles par le niveau national. Dès qu’une information supplémentaire sera portée à votre connaissance, complétez immédiatement le formulaire.',
    },
    signalant: {
      address: {
        label: 'Organisation ou établissement où travaille le signalant',
      },
      lastname: {
        label: 'Nom',
      },
      firstname: {
        label: 'Prénom',
      },
      organisation: {
        label: 'Organisation',
      },
      function: {
        label: "Fonction dans l'organisation",
      },
      phone: {
        label: 'Téléphone',
      },
    },
    modifications: {
      CREATE: 'Création',
      EDIT: 'Modification',
      CLOSE: 'Clotûre',
      ARCHIVE: 'Archivage',
    },
  },
  notifications: {
    exitDeclaration:
      'Confirmer la sortie du formulaire ? \n\rLes données de la fiche actuelle seront accessibles dans la liste des signalements avec le statut "{status}"',
  },
  account: {
    role: {
      DEPARTMENTAL: 'Départemental',
      NATIONAL: 'National',
    },
  },
  departments: {
    AR: 'Artibonite',
    CE: 'Centre',
    GA: "Grand'Anse",
    NI: 'Nippes',
    ND: 'Nord',
    NE: 'Nord Est',
    NO: 'Nord Ouest',
    OU: 'Ouest',
    SD: 'Sud',
    SE: 'Sud-Est',
  },
  sections: {
    ALERT_NUMBER: "Numéro d'alerte",
    PERMANENCIER: 'Permanencier',
    DEPARTMENT: 'Département',
    SIGNALANT: 'Signalant',
    DECEASED: 'Nombre de décès estimés',
    INJURIED: 'Nombre de blessés estimés',
    EVENT_LOCATION: "Lieu de l'évènement",
    STRUCTURES: 'Structures/Services',
    EVENT_TYPE: "Type d'évènement",
    EVENT_DATE: "Date de l'évènement",
    EVENT_DESCRIPTION: "Desription de l'évènement",
    ACTIONS: 'Actions entreprises',
    ALERTED_PEOPLE: 'Personnes alertées',
  },
};
