import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { formatDate } from 'utils/DateUtils';

const Alerte = ({ alertNumber = '', signalmentDate = '', isFilled }) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Alerte N°"
            style={{ margin: 8 }}
            placeholder="Numéro d'alerte"
            fullWidth
            margin="normal"
            disabled={true}
            value={alertNumber}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Signalement"
            style={{ margin: 8 }}
            placeholder="Date de signalement"
            fullWidth
            margin="normal"
            disabled={true}
            format={'dd-MM-yyyy'}
            value={formatDate(signalmentDate)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(Alerte);
