import { action, computed, observable, toJS, decorate } from 'mobx';
import { FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE } from '.';

class Field {
  clear = action(() => {
    this._value = undefined;
  });

  setDisabled = action('FIELD_SET_DISABLED', value => {
    this.disabled = value;
  });

  markAsTouch = action('FIELD_MARK_AS_TOUCH', () => {
    if (!this._interacted) {
      this._interacted = true;
    }
  });

  markAsPristine = action('FIELD_MARK_AS_PRISTINE', () => {
    if (this._interacted) {
      this._interacted = false;
    }
  });

  get value() {
    if (this.computeValue) {
      return this.computeValue(this._value);
    }
    return this._value;
  }

  setValue = action('SET_FIELD_VALUE', (val, interract = true) => {
    if (interract && !this._interacted) {
      this._interacted = true;
    }
    this._value = val;
  });

  onChange = (evt, value) => {
    if (evt.target && evt.target.value !== this._value) {
      this.setValue(evt.target.value);
    }
    if (this._validateOn.includes(FORM_VALID_ON_CHANGE)) {
      this.validate();
    }
  };

  onChangeValue = (value, evt) => {
    if (value && value !== this._value) {
      this.setValue(value);
    }
    if (this._validateOn.includes(FORM_VALID_ON_CHANGE)) {
      this.validate();
    }
  };

  onBlur = evt => {
    this.markAsTouch();
    if (this._validateOn.includes(FORM_VALID_ON_BLUR)) {
      this.validate();
    }
  };

  setValidation = action('SET_FIELD_VALIDATION', validation => {
    this._validation = validation;
  });

  get errorMessage() {
    // if (!this._interacted) {
    //   return '';
    // }
    return this._validation.message;
  }

  get isValid() {
    return this._validation.valid;
  }

  get isTouched() {
    return this._interacted;
  }

  jsValue() {
    return toJS(this._value);
  }

  validate = async () => {
    let lastRes;
    // FIXME: better code
    // if (this.value && this.value.map) {
    //   this.value.map(() => null);
    // }

    for (let i = 0; i < this._isValidFn.length; i++) {
      const isValidFn = this._isValidFn[i];
      let res = isValidFn(this._value, this.label);
      if (typeof res === 'function') {
        // Async validation must occure in a second function in order to let mobx detect used variables
        res = await res();
      }

      if (res === null || res === 'undefined' || !(typeof res === 'object')) {
        throw new Error('Invalid validator result');
      }
      lastRes = res;
      if (!res.valid || res.stopValidation) {
        break;
      }
    }

    if (lastRes) {
      this.setValidation(lastRes);
    }
    // console.debug(`validating field ${this.label}: ${lastRes && lastRes.valid}`);
  };

  constructor({
    initValue = '',
    validate,
    label = '',
    // debounce = false,
    disabled = false,
    computeValue,
    validateOn,
  } = {}) {
    this._interacted = false;
    this.opts = {};
    this._isValidFn = [];
    this._validateOn = [];
    this.disabled = disabled;
    this.label = label;
    this.computeValue = computeValue;
    this.setValue(initValue, false);
    if (validate) {
      if (validate instanceof Array) {
        this._isValidFn = validate;
      } else {
        this._isValidFn = [validate];
      }
    }
    if (validateOn) {
      if (validateOn instanceof Array) {
        this._validateOn = validateOn;
      } else {
        this._validateOn = [validateOn];
      }
    }

    if (this._isValidFn.length) {
      this._validation = { valid: false };
      // autorun(this.validate, { delay: debounce ? 1000 : 0 });
    } else {
      this._validation = { valid: true };
    }
  }
}

export default decorate(Field, {
  _value: observable,
  _interacted: observable,
  _validation: observable,
  disabled: observable,
  value: computed,
  errorMessage: computed,
  isValid: computed,
  isTouched: computed,
});
