import { action, decorate, observable, computed } from 'mobx';
import {
  ALERT_NUMBER,
  PERMANENCIER,
  DEPARTMENT,
  SIGNALANT,
  DECEASED,
  INJURIED,
  EVENT_LOCATION,
  EVENT_TYPE,
  EVENT_DATE,
  EVENT_DESCRIPTION,
  ACTIONS,
} from 'constants/index';
import {
  validateMode,
  isEditionOrConsultationMode,
  isConsultationMode,
  isCreationMode,
} from 'utils/forms';

class DeclarationStore {
  constructor(rootStore) {
    this.authenticationStore = rootStore.authenticationStore;
    this.notificationStore = rootStore.notificationStore;
    this.initStore();
  }

  _declaration;
  _sections;
  _mode;
  _commentMode = false;

  // initStore = action('DECLARATION_DATA_INIT', (mode, data) => {
  initStore = action((mode, data = {}) => {
    // console.debug('initStore');
    // console.debug(data);
    this._commentMode = false;
    this._declaration = data;
    // this.changeMode(mode);
    const showSection = isEditionOrConsultationMode(this._mode);
    this._sections = new Map([
      [PERMANENCIER, showSection && data && !!data.permanencier],
      [DEPARTMENT, showSection && data && !!data.department],
      [ALERT_NUMBER, showSection && data && !!data.alertNumber],
      [SIGNALANT, showSection && data && !!data.signalant],
      [EVENT_DATE, showSection && data && !!data.eventDate],
      [EVENT_LOCATION, showSection && data && !!data.eventLocation],
      [EVENT_TYPE, showSection && data && !!data.eventType],
      [INJURIED, showSection && data && !!data.injuried],
      [DECEASED, showSection && data && !!data.deceased],
      [EVENT_DESCRIPTION, showSection && data && !!data.eventDescription],
      [ACTIONS, showSection && data && !!data.actions && data.actions.length > 0],
    ]);
  });

  updateData = action('DECLARATION_DATA_UPDATE', declaration => {
    this._declaration = declaration;
  });

  sectionFilled = action('DECLARATION_SECTION_FILLED', sectionName => {
    if (this._sections.has(sectionName)) {
      this._sections.set(sectionName, true);
    }
  });

  sectionUnfilled = action('DECLARATION_SECTION_UNFILLED', sectionName => {
    if (this._sections.has(sectionName)) {
      this._sections.set(sectionName, false);
    }
  });

  isFilled(sectionName) {
    return !!this._sections.get(sectionName);
  }

  resetStore = () => {
    this.initStore();
  };

  hasSectionFilled = () => {
    return Array.from(this._sections.values()).filter(val => val === true).length;
  };

  sectionShouldDisplay = (previousSection, currentSection) => {
    return (
      (isConsultationMode(this._mode) && this.isFilled(currentSection)) ||
      (this.isFilled(PERMANENCIER) && this.isFilled(previousSection))
    );
  };

  toggleCommentMode = forceMode => {
    if (forceMode !== undefined && forceMode !== null) {
      this._commentMode = !!forceMode;
    } else {
      this._commentMode = !this._commentMode;
    }
  };

  changeMode = mode => {
    console.debug('Changing form mode to :' + mode);
    if (mode !== this._mode) this._mode = validateMode(mode);
  };

  updateComments = comments => {
    this._declaration = {
      ...this._declaration,
      comments: comments,
    };
  };

  get mode() {
    return this._mode;
  }

  get declaration() {
    return this._declaration;
  }

  get isCreationMode() {
    return isCreationMode(this._mode);
  }

  get isEditable() {
    const { isNational } = this.authenticationStore;
    switch (this._declaration.status) {
      case 'DRAFT':
      case 'VALIDATED':
        return true;
      default:
        // National user can always edit
        return isNational;
    }
  }

  get sectionsFilledForValidation() {
    const areSectionsFilled =
      Array.from(this._sections.values()).filter(val => val).length === this._sections.size;
    return areSectionsFilled;
  }

  get isCommentMode() {
    return this._commentMode;
  }

  get status() {
    return this._declaration.status;
  }
}

export default decorate(DeclarationStore, {
  _declaration: observable,
  _sections: observable,
  _mode: observable,
  _commentMode: observable,
  declaration: computed,
  mode: computed,
  isCreationMode: computed,
  sectionsFilledForValidation: computed,
  initStore: action,
  resetStore: action,
  changeMode: action,
  updateComments: action,
  isEditable: computed,
  isCommentMode: computed,
  toggleCommentMode: action,
  status: computed,
});
