import DeclarationStore from './DeclarationStore';
import AuthenticationStore from './AuthenticationStore';
import NotificationStore from './NotificationStore';
import RouterStore from './RouterStore';

class RootStore {
  routingStore = new RouterStore();
  notificationStore = new NotificationStore(this);
  authenticationStore = new AuthenticationStore(this);
  declarationStore = new DeclarationStore(this);
}

export default RootStore;
