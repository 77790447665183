export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatPhoneNumber(str) {
  const input = str.replace(/\s/g, '').replace(/\D{1,8}/g, ''); // First ten digits of input only
  const bloc1 = input.substring(0, 4);
  const bloc2 = input.substring(4, 8);

  if (input.length > 4) {
    return `${bloc1} ${bloc2}`;
  } else {
    return bloc1;
  }
}
