import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject } from 'mobx-react';
import compose from 'utils/compose';
import { observer } from 'mobx-react-lite';
import Loader from './Loader';

const AuthorizedRoute = ({
  component: Component,
  redirectTo,
  roles = null,
  authenticationStore,
  render,
  ...rest
}) => {
  const { isReady, isAuthenticated, user } = authenticationStore;
  let isAuthorized = false;
  if (isAuthenticated) {
    const userRole = user && user.role;
    if (roles) {
      isAuthorized = roles.includes(userRole);
      // console.debug('user is authorized ' + isAuthorized);
    } else {
      isAuthorized = true;
    }
  }
  // console.debug('User is authenticated :' + isAuthenticated);
  return (
    <Route
      {...rest}
      render={props => {
        if (!isReady) {
          return <Loader />;
        } else if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        } else {
          return isAuthorized ? (
            Component ? (
              <Component {...props} />
            ) : (
              render(props)
            )
          ) : (
            <Redirect
              to={{
                pathname: redirectTo || '/',
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default compose(
  inject('authenticationStore'),
  observer
)(AuthorizedRoute);
