import React from 'react';
import { isEditionOrCreationMode } from 'utils/forms';
import { Grid, Button, Typography, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import compose from 'utils/compose';

const withEditableForm = WrappedComponent => {
  const styles = theme => ({
    cancelButton: {
      marginLeft: theme.spacing(2),
    },
  });
  return compose(
    withStyles(styles),
    observer
  )(props => {
    const { buttonLabel = 'Enregistrer', classes, mode, form, onSubmit, onCancel } = props;
    const editable = isEditionOrCreationMode(mode);
    const handleSubmit = async e => {
      console.debug('[HandleSubmit] form is being submitted');
      e.preventDefault();
      if (!isEditionOrCreationMode(mode)) return;
      await form.validate();
      if (form.isValid) {
        onSubmit(form.values).then(() => form.setPristine());
      }
    };
    if (form)
      return (
        <form id={props.id} noValidate={true} onSubmit={handleSubmit}>
          <WrappedComponent {...props} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isEditionOrCreationMode(mode) && (
                <Grid container justify="flex-start" alignItems="center">
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!form.isTouched}>
                      <Typography variant="button" color="inherit" noWrap>
                        {buttonLabel}
                      </Typography>
                    </Button>
                    {onCancel && (
                      <Button
                        variant="contained"
                        onClick={onCancel}
                        disabled={!editable}
                        className={classes.cancelButton}>
                        <Typography variant="button" color="inherit" noWrap>
                          Annuler
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      );
    else
      return (
        <div>
          <Typography variant="body2" noWrap>
            Chargement en cours ...
          </Typography>
        </div>
      );
  });
};

export default withEditableForm;
